import './InlineInputConfigEditor.scss';
import React, {ChangeEvent, useEffect, useState} from "react";
import classnames from "classnames";
import {
    ByzzerButton,
    ByzzerChangeEvent,
    ByzzerChangeEventHandler,
    ByzzerSelect,
    ByzzerSelectOption
} from "@byzzer/ui-components";
import {SimulatorConfig, SimulatorInputConfig} from "@/types/types";
import {useOutletContext} from "react-router-dom";
import {useSimulator} from "@/pages/Simulator";

export type InlineInputConfigEditorProps = {
    name?: string;
    value: SimulatorInputConfig;
    onChange: ByzzerChangeEventHandler<SimulatorInputConfig>;
    simulator: SimulatorConfig;
    columnCount?: number;
    onRemove?(name: string | undefined): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'inline-input-config-editor';

export function InlineInputConfigEditor({
                                            className,
                                            simulator,
                                            name,
                                            value,
                                            columnCount = 1,
                                            onChange,
                                            onRemove,
                                            ...props
                                        }: InlineInputConfigEditorProps) {

    const {variableOptions} = useSimulator();

    function handleLabelChange({target}: ChangeEvent<HTMLInputElement>): void {
        onChange?.({
            name,
            value: {
                ...value,
                label: target.value
            }
        })
    }

    function handleSourceChange(e: ByzzerChangeEvent<string>) {
        const index = Number(e.name);
        const leverCodes = value.inputCodes;
        leverCodes[index] = e.value;
        onChange?.({
            name,
            value: {
                ...value,
                inputCodes: [...leverCodes]
            }
        })
    }

    return <div
        className={classnames(baseClassName, className, {
            [`${baseClassName}--single-value`]: columnCount === 1
        })} {...props}>

        <input className={`${baseClassName}__label`}
               type={'text'}
               value={value.label}
               name={'label'}
               onChange={handleLabelChange}/>
        {Array.from({length: columnCount}).map((_, index) => (
            <ByzzerSelect key={index}
                          name={String(index)}
                          className={`${baseClassName}__lever-selector`}
                          value={value.inputCodes[index]}
                          onChange={handleSourceChange as any}
                          allowClear={false}
                          options={variableOptions}/>

        ))}
        <ByzzerButton onClick={() => onRemove?.(name)}
                      type={'remove'}
                      iconType={'delete'}
                      tipDelay={[250, 0]}
                      tipLocation={'bottom'}
                      tip={'Remove'}/>
    </div>
}

export default InlineInputConfigEditor;