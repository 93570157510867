import './SimulatorProductEditor.scss';
import React, {ChangeEvent, CSSProperties, useEffect, useState} from "react";
import classnames from "classnames";
import {useSimulator} from "@/pages/Simulator";
import {
    ByzzerButton,
    ByzzerChangeEvent,
    ByzzerCheckableChangeEvent,
    ByzzerCheckbox,
    ByzzerTextInput
} from "@byzzer/ui-components";
import {openImagePicker} from "@/components/ImagePicker";
import {inputToRawValue} from "@/utils";

export type SimulatorSummaryProps = {} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator-product-editor';

export function SimulatorProductEditor({className, ...props}: SimulatorSummaryProps) {

    const {simulator, setSimulator} = useSimulator();
    const [subscriptionLevelText, setSubscriptionLevelText] = useState<string>('')

    useEffect(() => {
        setSubscriptionLevelText(String(simulator.subscriptionLevel ?? ''));
    }, [simulator.subscriptionLevel]);

    function handleValueChange(e: ByzzerChangeEvent<string>) {

        setSimulator(simulator => ({
            ...simulator,
            [e.name!]: e.value
        }))
    }

    function handleTextAreaChange({target}: ChangeEvent<HTMLTextAreaElement>) {

        setSimulator(simulator => ({
            ...simulator,
            [target.name]: target.value
        }))
    }

    async function chooseImage() {
        const thumbnailUrl = await openImagePicker();
        if (!thumbnailUrl) return;

        setSimulator(simulator => ({
            ...simulator,
            thumbnailUrl
        }))
    }

    function handleBooleanChange(e: ByzzerCheckableChangeEvent<string>): void {

        setSimulator(simulator => ({
            ...simulator,
            [e.name!]: e.checked
        }));
    }

    function handleAccessLevelChange(e: ByzzerCheckableChangeEvent<string>): void {

        if (e.checked) {
            setSimulator(simulator => ({
                ...simulator,
                allowedAccessLevels: [...simulator.allowedAccessLevels, e.name as AccessLevel]
            }));
        } else {
            setSimulator(simulator => ({
                ...simulator,
                allowedAccessLevels: simulator.allowedAccessLevels.filter(v => v !== e.name)
            }));
        }
    }

    function handleSubscriptionLevelChange(e: ByzzerChangeEvent<string>): void {

        if(!/^-?\d*\.?\d*$/.test(e.value)) return;

        setSubscriptionLevelText(e.value);
    }

    function applySubscriptionLevel() {

        setSimulator(simulator => ({
            ...simulator,
            subscriptionLevel: subscriptionLevelText ? Number(subscriptionLevelText) : undefined
        }))
    }

    if (!simulator) return <></>

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__summary`}>
            <ByzzerTextInput className={`${baseClassName}__title`} value={simulator.displayName}
                             onChange={handleValueChange} name={'displayName'}
                             label={'Title'}/>
            <h2>Description</h2>
            <textarea className={`${baseClassName}__description`} value={simulator.description}
                      onChange={handleTextAreaChange} name={'description'}/>
            <ByzzerTextInput className={`${baseClassName}__subscription-level`}
                             label={'Subscription Level'}
                             value={subscriptionLevelText}
                             onBlur={applySubscriptionLevel}
                             onChange={handleSubscriptionLevelChange}
                             name={'subscriptionLevel'}/>
            <div className={`${baseClassName}__access-levels`}>
                <h2>Access Levels</h2>
                <ByzzerCheckbox className={`${baseClassName}__access-level`}
                                label={'All Else'} name={'all_else'}
                                checked={simulator.allowedAccessLevels.includes('all_else')}
                                onChange={handleAccessLevelChange}/>
                <ByzzerCheckbox className={`${baseClassName}__access-level`}
                                label={'Tracked Manufacturer'}
                                name={'tracked_manufacturer'}
                                checked={simulator.allowedAccessLevels.includes('tracked_manufacturer')}
                                onChange={handleAccessLevelChange}/>
                <ByzzerCheckbox className={`${baseClassName}__access-level`}
                                label={'Retailer'} name={'retailer'}
                                checked={simulator.allowedAccessLevels.includes('retailer')}
                                onChange={handleAccessLevelChange}/>
                <ByzzerCheckbox className={`${baseClassName}__access-level`}
                                label={'Unknown'} name={'unknown'}
                                checked={simulator.allowedAccessLevels.includes('unknown')}
                                onChange={handleAccessLevelChange}/>
                <ByzzerCheckbox className={`${baseClassName}__access-level`}
                                label={'Banks Finance'} name={'banks_finance'}
                                checked={simulator.allowedAccessLevels.includes('banks_finance')}
                                onChange={handleAccessLevelChange}/>
                <ByzzerCheckbox className={`${baseClassName}__access-level`}
                                label={'Consulting'} name={'consulting'}
                                checked={simulator.allowedAccessLevels.includes('consulting')}
                                onChange={handleAccessLevelChange}/>
            </div>
            <ByzzerCheckbox className={`${baseClassName}__active`}
                            label={'Active'} name={'active'} checked={simulator.active}
                            onChange={handleBooleanChange}/>

        </div>
        <div className={`${baseClassName}__thumbnail`}>
            <div className={classnames(`${baseClassName}__thumbnail-preview`, {
                [`${baseClassName}__thumbnail-preview--empty`]: !simulator.thumbnailUrl
            })} style={{
                '--image': `url('${simulator.thumbnailUrl}')`
            } as CSSProperties} onClick={chooseImage}/>
            <ByzzerTextInput className={`${baseClassName}__thumbnail-url`}
                             value={simulator.thumbnailUrl}
                             placeholder={'Enter a thumbnail url.'}
                             onChange={handleValueChange} name={'thumbnailUrl'}/>
            <ByzzerButton label={'Choose Thumbnail'} onClick={chooseImage}/>
        </div>
    </div>
}

export default SimulatorProductEditor;