import './ByzzerMask.scss';
import React, {ReactNode} from "react";
import cx from "classnames";
import Lottie, {LottieOptions} from "lottie-react";

export type ByzzerMaskProps = {
    className?: string;
    theme?: 'light' | 'dark' | string;
    loading?: boolean;
    show?: boolean;
    children?: ReactNode;
    lottieOptions?: LottieOptions;
}

const baseClassName = 'byzzer-mask';

export function ByzzerMask(
    {
        className,
        theme = 'light',
        loading = false,
        show = loading,
        children,
        lottieOptions,
    }: ByzzerMaskProps) {


    if (!show) return <></>;


    return (
        <div className={cx(baseClassName, className, {
            [`${baseClassName}--${theme}`]: theme
        })}>
            {loading && (
                <svg className={`${baseClassName}__loading-spinner`} viewBox="0 0 100 100"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle pathLength={1} cx="50" cy="50" r="45"/>
                </svg>
            )}
            {Boolean(lottieOptions) && (
                <Lottie className={`${baseClassName}__lottie`} animationData={lottieOptions} autoPlay={true}/>
            )}
            {children && (
                <div className={`${baseClassName}__message`}>{children}</div>
            )}
        </div>
    );
}
