import './PanelExpansionToggle.scss';
import React from "react";
import classnames from "classnames";
import { usePanelExpansion} from "@/components/PanelExpansionToggle/PanelExpansionContext";

export type PanelExpansionToggleProps = {
    panel: string;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'panel-expansion-toggle';

export function PanelExpansionToggle({className, panel, ...props}: PanelExpansionToggleProps) {

    const {toggleActivePanel, activePanel} = usePanelExpansion();

    function handleClick() {
        toggleActivePanel(panel)
    }

    return <div className={classnames(baseClassName, className, {
        [`${baseClassName}--expanded`]: activePanel === panel
    })} onClick={handleClick}>
    </div>
}

export default PanelExpansionToggle;