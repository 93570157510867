import './SimulatorLayoutEditor.scss';
import React from "react";
import classnames from "classnames";
import {SimulatorConfig} from "@/types/types";
import {ByzzerChangeEvent} from "@byzzer/ui-components";
import SimulatorChartConfigEditor from "@/components/SimulatorLayoutEditor/panels/SimulatorChartConfigEditor";
import {useSimulator} from "@/pages/Simulator";
import {
    SimulatorStaticValueEditorPanel
} from "@/components/SimulatorLayoutEditor/panels/SimulatorStaticValueEditorPanel";
import {SimulatorTablesEditorPanel} from "@/components/SimulatorLayoutEditor/panels/SimulatorTablesEditorPanel";
import {SimulatorPanelContainer} from "@/components/SimulatorPanelContainer";
import SimulatorInputEditorPanel
    from "@/components/SimulatorLayoutEditor/panels/SimulatorInputEditorPanel/SimulatorInputEditorPanel";

export type SimulatorLayoutEditorProps = {} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator-layout-editor';


export function SimulatorLayoutEditor({className, ...props}: SimulatorLayoutEditorProps) {

    const {simulator, setSimulator} = useSimulator();

    function handleSimulatorChange(e: ByzzerChangeEvent<ValueOf<SimulatorConfig>>) {


        setSimulator({
            ...simulator,
            [e.name!]: e.value
        });
    }

    return <SimulatorPanelContainer className={classnames(baseClassName, className)} {...props}>

        <SimulatorInputEditorPanel
            className={classnames(`${baseClassName}__inputs`)}
            name={'inputGroups'}
            groups={simulator.inputGroups}
            onChange={handleSimulatorChange}
        />
        <SimulatorStaticValueEditorPanel
            className={classnames(`${baseClassName}__static-values`)}
            name={'staticValues'}
            simulator={simulator}
            onChange={handleSimulatorChange}/>
        <SimulatorChartConfigEditor
            className={classnames(`${baseClassName}__chart`)}
            name={'chartConfig'}
            simulator={simulator}
            onChange={handleSimulatorChange}/>

        <SimulatorTablesEditorPanel
            className={classnames(`${baseClassName}__tables`)}
            name={'tableConfigs'}
            simulator={simulator}
            onChange={handleSimulatorChange}
        />
    </SimulatorPanelContainer>
}

export default SimulatorLayoutEditor;