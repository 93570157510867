import * as Guards from './guards';
import {buildRoutes} from "@/utils/RouteBuilder";
import {Home} from "../components/Home";
import {SimulatorDataSetup} from "@/components/SimulatorDataSetup";
import {SimulatorLayoutEditor} from "@/components/SimulatorLayoutEditor";
import {SimulatorPreview} from "@/components/SimulatorPreview";
import {Simulator} from "@/pages/Simulator";
import Login from "@/pages/Login/Login";
import {Catalog} from "@/pages/Catalog";
import {SimulatorProductEditor} from "src/components/SimulatorProductEditor";

const titlePrefix = 'Byzzer Simulator Studio';

export const routes = buildRoutes([
    {
        title: `${titlePrefix} - Login`,
        path: '/login',
        element: <Login/>,
        guard: Guards.unauthenticated,
    }, {
        title: `${titlePrefix} - Home`,
        path: '/home',
        element: <Home/>,
        default: true,
    }, {
        title: `${titlePrefix} - Simulator Catalog`,
        path: '/:env/simulators',
        element: <Catalog/>,
        guard: Guards.authenticated,
    }, {
        title: `${titlePrefix} - Simulator`,
        path: '/:env/editor/:id?',
        guard: Guards.authenticated,
        element: <Simulator/>,
        children: [{
            title: `${titlePrefix} - Build Your Simulator`,
            path: 'data',
            element: <SimulatorDataSetup/>
        }, {
            title: `${titlePrefix} - Layout Your Simulator`,
            path: 'layout',
            element: <SimulatorLayoutEditor/>
        }, {
            title: `${titlePrefix} - Preview Your Simulator`,
            path: 'preview',
            element: <SimulatorPreview/>
        }, {
            title: `${titlePrefix} - Summary`,
            path: 'product',
            default: true,
            element: <SimulatorProductEditor/>
        }]
    }
]);