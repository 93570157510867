import './SimulatorInputLevers.scss';
import React, {Fragment, useEffect, useState} from "react";
import classnames from "classnames";
import {SimulatorConfig, SimulatorInputGroupLayout, SimulatorValues} from "@/types/types";
import {ByzzerButton, ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerTipIcon} from "@byzzer/ui-components";
import {getTypeByCode} from "@/utils";
import {ValueManager} from "@/utils/ValueManager";
import {useSimulator} from "@/pages/Simulator";
import {LeverInput} from "src/components/LeverInput";

export type SimulatorInputLeversProps = {
    name?: string;
    simulator?: SimulatorConfig;
    onApply?: ByzzerChangeEventHandler<SimulatorValues>;
    onChange?: ByzzerChangeEventHandler<Record<string, number | undefined>>;
    values: SimulatorValues;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const inputCountsByLayout: Record<SimulatorInputGroupLayout, number> = {
    "label-input": 1,
    "label-input-label-input": 1,
    "label-input2x": 2,
    "label-input3x": 3,
};

const baseClassName = 'simulator-input-levers';

export function SimulatorInputLevers({
                                         className,
                                         name,
                                         values,
                                         simulator,
                                         onChange,
                                         onApply,
                                         ...props
                                     }: SimulatorInputLeversProps) {

    const [leverValues, setLeverValues] = useState<Record<string, number | undefined>>({});
    const [calculationCodes, setCalculationCodes] = useState<string[]>([]);
    const [valueManager, setValueManager] = useState<ValueManager>(new ValueManager());
    const {dataset} = useSimulator();

    useEffect(() => {

        if (simulator) {
            const calcCodes = simulator.calculations.map(v => v.code);
            setCalculationCodes(calcCodes ?? []);
            const valueManager = new ValueManager({
                staticValueConfigs: dataset?.valueConfigs,
                calculations: simulator.calculations,
                leverConfigs: simulator.leverConfigs,
            });
            setValueManager(valueManager);
            setLeverValues(valueManager.getRawValues());
            onChange?.({
                name,
                value: valueManager.getRawValues()
            });
        }
    }, [simulator, dataset])

    function handleChange(e: ByzzerChangeEvent<number | undefined>): void {

        valueManager.updateLever(e.name!, e.value);
        setLeverValues(valueManager.getRawValues());
        onChange?.({
            name,
            value: valueManager.getRawValues()
        });
    }

    function handleApplyClick() {
        onApply?.({
            name,
            value: valueManager.getRawValues()
        })
    }

    // todo add support column headers

    return <div className={classnames(baseClassName, className)} {...props}>
        <header>
            <h1 className={'simulator-preview__title'}>Levers</h1>
            {/*    todo: add support for tool tips here */}
        </header>
        <main className={`${baseClassName}__lever-groups`}>
            {simulator?.inputGroups.map(({layout = 'label-input3x', title, headers = [], inputs = []}, index) => {

                const inputCount = inputCountsByLayout[layout];
                const includeHeaders = ['label-input3x', 'label-input2x'].includes(layout);
                const indexArray = Array.from({length: inputCount});

                return <div
                    key={index}
                    className={classnames(`${baseClassName}__lever-group`, `${baseClassName}__lever-group--${layout}`)}>
                    {Boolean(title) && (
                        <h2 className={`${baseClassName}__lever-group-title`}>{title}</h2>
                    )}
                    {includeHeaders && (<>
                        <div/>
                        {indexArray.map((_, i) => (
                            <div key={i} className={`${baseClassName}__header`}>
                                {headers[i]}
                            </div>
                        ))}
                    </>)}
                    {inputs.map(({label, inputCodes, tip}, i) => (<Fragment key={i}>
                        <div className={`${baseClassName}__label`}>
                            {label}
                            {tip && (
                                <ByzzerTipIcon tip={tip}/>
                            )}
                        </div>
                        {indexArray.map((_, i) => {
                            const code = inputCodes[i];
                            return <LeverInput key={i} className={classnames(`${baseClassName}__input`)}
                                               name={code}
                                               type={getTypeByCode(code, simulator)}
                                               onChange={handleChange}
                                               value={leverValues[code]}
                                               readOnly={calculationCodes.includes(code)}/>
                        })}
                    </Fragment>))}
                </div>
            })}
        </main>
        <footer className={`${baseClassName}__actions`}>
            {Boolean(onApply) && (
                <ByzzerButton onClick={handleApplyClick}>Run Scenario</ByzzerButton>
            )}
        </footer>
    </div>
}


export default SimulatorInputLevers;