import './ByzChart.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
// @ts-ignore
import ZingChart from 'zingchart-react';
import { merge } from 'lodash';

export type ByzChartType =
    'area'
    | 'area3d'
    | 'bar'
    | 'bar3d'
    | 'boxplot'
    | 'bubble'
    | 'bubble-pack'
    | 'bubble-pie'
    | 'bullet'
    | 'calendar'
    | 'chord'
    | 'depth'
    | 'flame'
    | 'funnel'
    | 'gauge'
    | 'grid'
    | 'hbar'
    | 'hbar3d'
    | 'hboxplot'
    | 'hbubble'
    | 'hbullet'
    | 'heatmap'
    | 'hfunnel'
    | 'hmixed'
    | 'hscatter'
    | 'hwaterfall'
    | 'hwaterfall3d'
    | 'line'
    | 'line3d'
    | 'mixed'
    | 'mixed3d'
    | 'navpie'
    | 'nestedpie'
    | 'null'
    | 'pareto'
    | 'piano'
    | 'pie'
    | 'pie3d'
    | 'pop-pyramid'
    | 'radar'
    | 'rankflow'
    | 'range'
    | 'ring'
    | 'ring3d'
    | 'scatter'
    | 'scorecard'
    | 'stock'
    | 'stream'
    | 'sunburst'
    | 'tilemap'
    | 'tree'
    | 'treemap'
    | 'varea'
    | 'variwide'
    | 'vbar'
    | 'vbar3d'
    | 'vbullet'
    | 'vectorplot'
    | 'venn'
    | 'vfunnel'
    | 'vline'
    | 'vrange'
    | 'vstream'
    | 'waterfall'
    | 'waterfall3d'
    | 'wordcloud'
    | 'zingchart.maps';

export type ByzChartText = {
    text: string;
    fontSize?: number;
}

export type ByzChartConfig = {
    title?: ByzChartText;
    legend?: {
        draggable?: boolean;
    },
    scaleX?: {
        // Set scale label
        label?: ByzChartText;
        // Convert text on scale indices
        labels?: string[];
        visible?: boolean;
    },
    scaleY?: {
        // Set scale label
        label?: ByzChartText;
        // Convert text on scale indices
        labels?: string[];
    },
    plot?: {
        'value-box'?: boolean | object;
        // Animation docs here:
        // https://www.zingchart.com/docs/tutorials/styling/animation#effect
        animation?: {
            effect?: 'ANIMATION_EXPAND_BOTTOM'; // todo: create union type
            method?: 'ANIMATION_STRONG_EASE_OUT'; // todo: create union type
            sequence?: 'ANIMATION_BY_NODE'; // todo: create union type
            speed?: number;
        }
        // this allows us to gradually expand the type
        [key: string]: any;
    };
    [key: string]: any;
}

export type ByzChartSeriesValue = {
    values: number[];
    text?: string;
    [key: string]: any;
}

export type ByzChartData = {
    series: ByzChartSeriesValue[];
    [key: string]: any;
}

export type ByzChartProps = {
    type?: ByzChartType;
    config?: ByzChartConfig;
    data?: ByzChartData;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'byz-chart';

export function ByzChart({
                             className,
                             type = 'bar',
                             config,
                             data,
                             ...props
                         }: ByzChartProps) {

    const [chartData, setChartData] = useState<object>({
        globals: {
            fontFamily: 'Aktiv Grotesk, sans-serif',
            fontWeight: 800,
            color: 'black',
            fontColor: 'black'
        },
        ...config,
        ...data,
        type,
        plotarea: {
            margin: 'dynamic',
            offsetY: '200px',
            offsetX: '200px'
        },
    });

    useEffect(() => {
        setChartData(merge({
            plotarea: {
                margin: 'dynamic',
            }
        }, {
            ...config,
            ...data,
            type
        }))
    }, [type, config, data]);

    return <div className={classnames(baseClassName, className)} {...props}>
        <ZingChart width={'100%'} height={'100%'} data={chartData}/>
    </div>
}

export default ByzChart;