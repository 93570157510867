import './SimulatorTablesEditorPanel.scss';
import React, {useEffect, useRef, useState} from "react";
import classnames from "classnames";
import SimulatorTableEditor, {
    SimulatorTableEditorRef
} from "@/components/SimulatorLayoutEditor/panels/SimulatorTablesEditorPanel/SimulatorTableEditor";
import {SimulatorConfig, SimulatorInputConfig, SimulatorTableConfig} from "@/types/types";
import {ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerSelect, ByzzerSelectOption} from "@byzzer/ui-components";
import {SimulatorPanel} from "@/components/SimulatorPanel";

export type SimulatorTablesEditorPanelProps = {
    name?: string;
    simulator: SimulatorConfig;
    onChange?: ByzzerChangeEventHandler<SimulatorTableConfig[]>;
    /**
     * Not implemented
     */
    onApply?: ByzzerChangeEventHandler<SimulatorInputConfig[]>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;
const baseClassName = 'simulator-tables-editor-panel';

export function SimulatorTablesEditorPanel({
                                               className,
                                               name,
                                               simulator,
                                               onChange,
                                               onApply,
                                               ...props
                                           }: SimulatorTablesEditorPanelProps) {

    const {tableConfigs} = simulator;
    const editorRef = useRef<SimulatorTableEditorRef>(null);
    const [activeIndex, setActiveIndex] = useState<number>()
    const [tableOptions, setTableOptions] = useState<ByzzerSelectOption[]>([]);
    const hasActiveTable = activeIndex !== undefined;

    useEffect(() => {
        setTableOptions(tableConfigs.map((c, i) => ({
            display: c.title,
            value: String(i)
        })))
    }, [tableConfigs])

    function addTable() {
        onChange?.({
            name,
            value: [...tableConfigs, {
                title: `Untitled (${tableConfigs.length + 1})`,
                columns: [{
                    title: '',
                    datatype: 'decimal'
                }, {
                    title: '',
                    datatype: "decimal"
                }],
                data: [['', '']]
            }]
        });
        setActiveIndex(tableConfigs.length)
    }

    function removeTable() {

        if(!confirm('Are you sure you want to remove this table?')) return;

        onChange?.({
            name,
            value: tableConfigs.filter((_, i) => i !== activeIndex)
        });
        setActiveIndex(undefined);
    }

    function addRow() {
        editorRef.current?.addRow();
    }

    function addColumn() {
        editorRef.current?.addColumn();
    }

    function handleConfigChange(e: ByzzerChangeEvent<SimulatorTableConfig>) {

        const index = Number(e.name);
        onChange?.({
            name,
            value: tableConfigs.map((config, i) => i === index ? e.value : config)
        });
    }

    function handleActiveChange(e: ByzzerChangeEvent<string>): void {
        setActiveIndex(Number(e.value));
    }

    return <SimulatorPanel className={classnames(baseClassName, className)}
                           name={'tables'}
                           title={`Outcome Tables (${tableConfigs.length})`}
                           primaryControl={
                               <ByzzerSelect onChange={handleActiveChange as any}
                                             value={String(activeIndex)}
                                             placeholder={'Choose a table to edit.'}
                                             allowClear={false}
                                             options={tableOptions}/>
                           }
                           actions={[
                               {
                                   include: hasActiveTable,
                                   label: 'Remove Table',
                                   type: 'remove',
                                   onClick: removeTable,
                                   className: `${baseClassName}__remove-table-btn`
                               },
                               {label: 'Add Table', onClick: addTable, className: `${baseClassName}__add-table-btn`},
                               {label: 'Add Row', onClick: addRow, include: hasActiveTable},
                               {label: 'Add Column', onClick: addColumn, include: hasActiveTable},
                           ]}
                           empty={tableConfigs.length === 0}
                           emptyContent={`You haven't added any outcome tables.`}
                           {...props}>
        {hasActiveTable && (
            <SimulatorTableEditor key={activeIndex} value={tableConfigs[activeIndex]} name={String(activeIndex)}
                                  onChange={handleConfigChange}
                                  byzRef={editorRef}/>
        )}

    </SimulatorPanel>
}

export default SimulatorTablesEditorPanel;