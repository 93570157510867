import {createContext, ReactNode, useContext, useMemo, useState} from "react";

export type PanelExpansionContextValue = {
    activePanel?: string;
    toggleActivePanel(panel: string): void;
    // todo: add most of the values are that are current passed around
};
export const PanelExpansionContext = createContext<PanelExpansionContextValue>({} as PanelExpansionContextValue);
export const usePanelExpansion = () => useContext(PanelExpansionContext);

type PanelExpansionProviderProps = {
    children: ReactNode;
}
export function PanelExpansionProvider({children}: PanelExpansionProviderProps) {

    const [activePanel, setActivePanel] = useState<string>();

    const contextValue = useMemo<PanelExpansionContextValue>(() => ({
        toggleActivePanel(panel: string): void {
            setActivePanel(activePanel => activePanel !== panel ? panel : undefined);
        },
        activePanel
    }), [activePanel]);

    return <PanelExpansionContext.Provider value={contextValue}>
        {children}
    </PanelExpansionContext.Provider>
}