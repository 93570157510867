import './ImagePicker.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {create} from "react-modal-promise";
import {modalify, openModal, useModalContext} from "@/components/Modal/Modal";
import {ByzzerButton, ByzzerChangeEvent} from "@byzzer/ui-components";
import ImagePickerUrl, {ImagePickerUrlProps} from "@/components/ImagePicker/ImagePickerUrl";
import ImagePickerUpload from "@/components/ImagePicker/ImagePickerUpload";
import ImagePickerCDN, {ImagePickerCDNProps} from "@/components/ImagePicker/ImagePickerCDN";

export type ImagePickerProps = {
    foo: string;
    bar: number;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'image-picker';

export type SourceTab = 'url' | 'cdn' | 'upload';
type TabComponentType = typeof ImagePickerUpload | typeof ImagePickerCDN | typeof ImagePickerUrl;
const componentToTab: Record<SourceTab, TabComponentType> = {
    cdn: ImagePickerCDN,
    upload: ImagePickerUpload,
    url: ImagePickerUrl
}

export function ImagePicker({className, ...props}: ImagePickerProps) {

    const {resolve} = useModalContext();
    const [activeTab, setActiveTab] = useState<SourceTab>('upload');
    let TabComponent: TabComponentType = componentToTab[activeTab];

    function handleApply(e: ByzzerChangeEvent<string>) {
        resolve(e.value);
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <nav className={`${baseClassName}__tabs`}>
            <li className={classnames(`${baseClassName}__tab`, {
                [`${baseClassName}__tab--active`]: activeTab === 'upload'
            })} onClick={() => setActiveTab('upload')}>Upload</li>
            <li className={classnames(`${baseClassName}__tab`, {
                [`${baseClassName}__tab--active`]: activeTab === 'cdn'
            })} onClick={() => setActiveTab('cdn')}>CDN</li>
            <li className={classnames(`${baseClassName}__tab`, {
                [`${baseClassName}__tab--active`]: activeTab === 'url'
            })} onClick={() => setActiveTab('url')}>Import From Web Address (URL)</li>
        </nav>
        <main className={`${baseClassName}__content`}>
            <TabComponent className={`${baseClassName}__input`} onApply={handleApply}/>
        </main>
    </div>
}

export const openImagePicker = modalify<string, ImagePickerProps>(ImagePicker, "Choose An Image")

export default ImagePicker;