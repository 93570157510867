import './SimulatorInputEditorPanel.scss';
import React, {useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {SimulatorPanel} from "@/components/SimulatorPanel";
import SimulatorInputGroupEditor
    , {
    SimulatorInputGroupEditorRef
} from "@/components/SimulatorLayoutEditor/panels/SimulatorInputEditorPanel/SimulatorInputGroupEditor";
import SimulatorInputLevers from "@/components/SimulatorPreview/SimulatorInputLevers";
import {SimulatorInputGroup} from "@/types/types";
import {ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerSelect, ByzzerSelectOption} from "@byzzer/ui-components";

export type SimulatorInputEditorPanelProps = {
    name?: string;
    groups: SimulatorInputGroup[];
    onChange: ByzzerChangeEventHandler<SimulatorInputGroup[]>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'simulator-input-editor-panel';

export function SimulatorInputEditorPanel({className, groups, name, onChange, ...props}: SimulatorInputEditorPanelProps) {

    const [activeIndex, setActiveIndex] = useState<number>(0);
    const [groupOptions, setGroupOptions] = useState<ByzzerSelectOption<SimulatorInputGroup>[]>([]);
    const editorRef = useRef<SimulatorInputGroupEditorRef>(null);
    const isEmpty: boolean = !groups.length;

    useEffect(() => {
        setGroupOptions(groups?.map((group, i) => ({
            display: group.title || `Untitled ${i + 1}`,
            value: String(i)
        })) ?? [])
    }, [groups])

    function handleGroupChange(e: ByzzerChangeEvent<SimulatorInputGroup>) {
        onChange?.({
            name,
            value: groups.map((group, i) => i === activeIndex ? e.value : group)
        })
    }

    function addGroup() {
        setActiveIndex(groups.length);
        onChange?.({
            name,
            value: [...groups, {
                title: "",
                layout: 'label-input3x',
                headers: ['', '', '',],
                inputs: [{
                    label: '',
                    inputCodes: ['', '', '',],
                }],
            }]
        })
    }

    function addInput() {
        editorRef.current?.addInput();
    }

    function removeGroup() {
        if(!confirm('Are you sure you want to remove this entire input group?')) return;
        setActiveIndex(Math.max(0, activeIndex - 1));
        onChange?.({
            name,
            value: groups.filter((_, i) => i !== activeIndex)
        })
    }

    function handleGroupIndexChange(e: ByzzerChangeEvent<number>): void {
        setActiveIndex(Number(e.value));
    }

    return <SimulatorPanel className={classnames(baseClassName, className)}
                           title={'Scenario'}
                           name={'inputs'}
                           empty={isEmpty}
                           emptyContent={`You haven't add any Levers.`}
                           primaryControl={
                               <ByzzerSelect value={String(activeIndex)} options={groupOptions}
                                             allowClear={false}
                                             onChange={handleGroupIndexChange as any}/>
                           }
                           actions={[{
                               include: groups?.length > 1,
                               onClick: removeGroup,
                               label: 'Remove Group',
                               type: 'remove',
                               className: `${baseClassName}__remove-group-btn`
                           }, {
                               onClick: addGroup,
                               label: 'Add Group',
                               className: `${baseClassName}__add-group-btn`,
                               tip: <>
                                   <p>Add a new group of levers.</p>
                                   <p>This can be used to change the layout of the group.</p>
                               </>
                           }, {
                               include: !isEmpty,
                               onClick: addInput,
                               label: 'Add Input'
                           }]}
                           {...props}>
        {Boolean(groups[activeIndex]) && (
            <SimulatorInputGroupEditor byzRef={editorRef} value={groups[activeIndex]} onChange={handleGroupChange}/>
        )}
    </SimulatorPanel>
}

export default SimulatorInputEditorPanel;