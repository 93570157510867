import './ImagePickerUrl.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {ByzzerButton, ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerTextInput} from "@byzzer/ui-components";
import {copyImageToCdn, uploadCdnFile} from "@/services/api.service";
import {isWebUri} from 'valid-url';
import { ByzzerMask } from '../ByzzerMask';

export type ImagePickerUrlProps = {
    onApply: ByzzerChangeEventHandler<string>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'image-picker-url';

export function ImagePickerUrl({className, onApply, ...props}: ImagePickerUrlProps) {

    const [url, setUrl] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [hasValidImage, setHasValidImage] = useState<boolean>(false);
    const [saving, setSaving] = useState<boolean>(false);

    async function handleApply() {

        if (!hasValidImage) return;

        let value = url;
        if (!url.startsWith('https://cdn.byzzer.com')) {
            try {
                setSaving(true)
                value = await copyImageToCdn(url, 'thumbnails');
            } catch(err) {
                alert('Image import failed.');
                return;
            } finally {
                setSaving(false)
            }
        }

        onApply({
            value
        })
    }

    function handleUrlChange(e: ByzzerChangeEvent<string>) {
        setUrl(e.value);
    }

    function handleImageLoad() {
        if (/^http.*\/.*/.test(url) && isWebUri(url) && !url.startsWith('https://cdn.byzzer.com')) {
            setMessage('Using this image will upload it to the Byzzer CDN.')
        } else {
            setMessage('');
        }
        setHasValidImage(true);
    }

    function handleImageError() {
        setMessage('');
        setHasValidImage(false);
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <main className={`${baseClassName}__content`}>
            <ByzzerMask loading={saving}>Just a moment while we copy your image to the CDN</ByzzerMask>
            <ByzzerTextInput className={`${baseClassName}__input`} onChange={handleUrlChange} value={url}
                             label={'Enter or paste the URL of an image.'}/>
            <div className={`${baseClassName}__preview`}>
                {Boolean(url) && (
                    <img className={`${baseClassName}__preview-image`}
                         src={url}
                         onError={handleImageError} onLoad={handleImageLoad}/>
                )}
            </div>
            {Boolean(message) && <div className={`${baseClassName}__message`}>{message}</div>}
        </main>
        <footer className={`${baseClassName}__actions`}>
            <ByzzerButton onClick={handleApply} disabledTip={'You must enter the url of a valid image first.'}
                          disabled={!hasValidImage}>Apply</ByzzerButton>
        </footer>
    </div>
}

export default ImagePickerUrl;