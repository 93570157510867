export * from './buildInfo';
export * from './firebase';

export const zingChartConfig = {
    appLicense: import.meta.env.REACT_APP_ZINGCHART_LICENSE
}

export const api = {
    ablyApiBaseUrl: import.meta.env.REACT_APP_ALBY_API_BASE_URL,
}
