export const chartTypeOptions = [
    {display: '3d Area', value: 'area3d'},
    {display: '3d Bar (Horizontal)', value: 'hbar3d'},
    {display: '3d Bar (Vertical)', value: 'vbar3d'},
    {display: '3d Bar', value: 'bar3d'},
    {display: '3d Pie', value: 'pie3d'},
    {display: '3d Ring', value: 'ring3d'},
    {display: 'Area', value: 'area'},
    {display: 'Area (Vertical)', value: 'varea'},
    {display: 'Bar', value: 'bar'},
    {display: 'Bar (Horizontal)', value: 'hbar'},
    {display: 'Bar (Vertical)', value: 'vbar'},
    {display: 'Bubble', value: 'bubble'},
    {display: 'Bullet (Horizontal)', value: 'hbullet'},
    {display: 'Bullet (Vertical)', value: 'vbullet'},
    {display: 'Bullet', value: 'bullet'},
    {display: 'Gauge', value: 'gauge'},
    {display: 'Heatmap', value: 'heatmap'},
    {display: 'Line (Vertical)', value: 'vline'},
    {display: 'Line', value: 'line'},
    {display: 'Pie (Navigable)', value: 'navpie'},
    {display: 'Pie', value: 'pie'},
    {display: 'Radar', value: 'radar'},
    {display: 'Range (Vertical)', value: 'vrange'},
    {display: 'Range', value: 'range'},
    {display: 'Ring', value: 'ring'},
    {display: 'Scatter (Horizontal)', value: 'hscatter'},
    {display: 'Scatter', value: 'scatter'},
    {display: 'Stock', value: 'stock'},
    {display: 'Waterfall (Horizontal)', value: 'hwaterfall'},
    {display: 'Waterfall', value: 'waterfall'},
].sort((a, b) => a.display.localeCompare(b.display));

// {display: 'Bubble Pack', value: 'bubble-pack'},
// {display: 'Calendar', value: 'calendar'},
// {display: 'Chord', value: 'chord'},
// {display: 'Depth', value: 'depth'},
// {display: 'Flame', value: 'flame'},
// {display: 'Funnel (Vertical)', value: 'vfunnel'},
// {display: 'Funnel', value: 'funnel'},
// {display: 'Mixed (Horizontal)', value: 'hmixed'},
// {display: 'Mixed', value: 'mixed'},
// {display: 'Pareto', value: 'pareto'},
// {display: 'Pop-pyramid', value: 'pop-pyramid'},
// {display: 'Rankflow', value: 'rankflow'},
// {display: 'Scorecard', value: 'scorecard'},
// {display: 'Sunburst', value: 'sunburst'},
// {display: 'Tilemap', value: 'tilemap'},
// {display: 'Tree', value: 'tree'},
// {display: 'Treemap', value: 'treemap'},
// {display: 'Variwide', value: 'variwide'},
// {display: 'Vector Plot', value: 'vectorplot'},
// {display: 'Venn', value: 'venn'},
// {display: 'Wordcloud', value: 'wordcloud'},