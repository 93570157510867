import './FormattedValue.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {SimulatorDatatype} from "@/types/types";
import {rawValueToDisplay} from "@/utils";

export type FormattedValueProps = {
    type?: SimulatorDatatype | undefined;
    value: number | undefined;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'formatted-value';

export function FormattedValue({className, type = 'decimal', value, ...props}: FormattedValueProps) {


    return <div className={classnames(baseClassName, className)} {...props}>
        {rawValueToDisplay(type, value)}
    </div>
}

export default FormattedValue;