export const buildInfo = {
    git: {
        sha: import.meta.env.REACT_APP_GIT_SHA,
        tag: import.meta.env.REACT_APP_GIT_TAG,
        dirty: import.meta.env.REACT_APP_GIT_DIRTY === 'true',
        branch: import.meta.env.REACT_APP_GIT_BRANCH
    },
    dtm: new Date(import.meta.env.REACT_APP_BUILD_DTM),
    version: import.meta.env.REACT_APP_VERSION,
    env: import.meta.env.REACT_APP_BYZZER_ENV,
};