import {SimulatorConfig, SimulatorDatatype} from "@/types/types";
import XLSX from "xlsx-js-style";
import {rawValueToInput} from "@/utils";
import convert from "color-convert";

type CellBorder = {
    style?: 'dashDotDot' |
        'dashDot' |
        'dashed' |
        'dotted' |
        'hair' |
        'mediumDashDotDot' |
        'mediumDashDot' |
        'mediumDashed' |
        'medium' |
        'slantDashDot' |
        'thick' |
        'thin';
    color?: string;
}
type CellFormatting = {
    font?: string;
    bgColor?: string;
    fontColor?: string;
    numFormat?: string;
    bold?: boolean;
    hAlign?: 'left' | 'right' | 'center';
    vAlign?: 'top' | 'bottom' | 'center';
    borderTop?: CellBorder | boolean;
    borderRight?: CellBorder | boolean;
    borderBottom?: CellBorder | boolean;
    borderLeft?: CellBorder | boolean;
}
type CellConfig = {
    value: string;
    formatting?: CellFormatting;
    rowSpan?: number;
    colSpan?: number;
}
type SpreadsheetConfig = {
    cells: (CellConfig | string | null | undefined)[][];
}

export function generateSpreadsheet(
    {cells}: SpreadsheetConfig,
    values: Record<string, number | undefined>,
    datatypeMapping: Record<string, SimulatorDatatype | undefined>,
    runConfig: any, // this is unused in the simulator and is always a static configuration but will have real data in the simulator
    simulator: SimulatorConfig
): void {

    const title = 'tbd';
    const wb = XLSX.utils.book_new();

// STEP 2: Create data rows and styles
    const content = cells.map(row => row.map(toCellValue));
    const merges: XLSX.Range[] = cells.map((row, r) => row.map((cell, c) => {
        const {rowSpan, colSpan} = (cell as CellConfig) ?? {};
        if (rowSpan || colSpan) {
            return {
                s: {r, c},
                e: {
                    r: r + (rowSpan ?? 1) - 1,
                    c: c + (colSpan ?? 1) - 1,
                }
            };
        }
    })).flat().filter(Boolean) as XLSX.Range[];

// STEP 3: Create worksheet with rows; Add worksheet to workbook
    const ws = XLSX.utils.aoa_to_sheet(content);
    ws['!merges'] = merges;
    XLSX.utils.book_append_sheet(wb, ws, `Simulation Export`);

// STEP 4: Write Excel file to browser
    XLSX.writeFile(wb, `${simulator.displayName}.xlsx`);

    function toCellValue(cell: CellConfig | string | null | undefined): any {
        if (!cell) return {v: ''};

        let text: string;
        let formatting: any;
        let datatype: SimulatorDatatype | undefined;
        if (typeof cell === 'string') {
            text = cell;
        } else {
            text = cell.value;
            formatting = toCellStyles(cell.formatting);
        }
        const rawValue = text.replace(/\{(.*?)}/g, (_: string, value: string): string => {
            if (value.startsWith('$')) {
                const code = value.slice(1);
                datatype = datatypeMapping[code] ?? 'decimal';
                return rawValueToInput(datatype, values[code])
            } else if (value.startsWith('simulator')) {
                return eval(value);
            } else if (value.startsWith('runConfig')) {
                // todo: this will probably need more logic because the runConfig structure is not flat
                return eval(value)
            }

            return '';
        });

        let value: string | number = rawValue;
        let numFmt: string | undefined;
        switch (datatype) {
            case 'currency':
                numFmt = '"$"#,##0.00_);\\("$"#,##0.00\\)';
                value = Number(value);
                break;
            case 'integer':
                numFmt = '#,##0';
                value = Number(value);
                break;
            case 'percent':
                numFmt = "0.00%";
                value = Number(value) / 100;
                break;
            case 'decimal':
                numFmt = '#,##0.00';
                value = Number(value);
                break;
        }
        return {
            v: value,
            t: typeof value === 'string' ? 's' : 'n',
            s: {
                ...formatting,
                numFmt
            }
        }
    }
}

function toCellStyles(config: CellFormatting | undefined): any {

    if (!config) return {};

    const {bgColor, fontColor, bold, hAlign, vAlign, borderTop, borderBottom, borderLeft, borderRight} = config;

    const result: any = {
        // if this is left as an empty object or fgColor is undefined it creates a black background
        fill: bgColor ? {
            fgColor: toColor(bgColor),
        }: undefined,
        font: {
            color: toColor(fontColor),
            bold,
        },
        alignment: {
            vertical: vAlign,
            horizontal: hAlign,
        },
        border: {
            top: toBorder(borderTop),
            right: toBorder(borderRight),
            bottom: toBorder(borderBottom),
            left: toBorder(borderLeft),
        }
    };

    return result;
}

function toBorder(border: CellBorder | boolean | undefined): { style?: string, color?: { rgb: string } } | undefined {

    if (!border) return;

    if (border === true) {
        return {
            style: 'thin'
        }
    }

    return {
        style: border.style,
        color: toColor(border.color)
    }
}

function toColor(color: string | undefined): { rgb: string } | undefined {

    if (!color) return;

    const hexColor: string = color.startsWith('#') ? color.slice(1) : convert.keyword.hex(color);
    return {
        rgb: hexColor.toUpperCase()
    };
}
