import './SimulatorPanel.scss';
import React, {ChangeEvent, ReactNode, useEffect, useImperativeHandle, useRef, useState} from "react";
import classnames from "classnames";
import PanelExpansionToggle from "../PanelExpansionToggle/PanelExpansionToggle";
import {
    ByzzerButton,
    ByzzerButtonProps,
    ByzzerChangeEvent,
    ByzzerTextInput,
    ByzzerTipIcon
} from "@byzzer/ui-components";
import {usePanelExpansion} from "@/components/PanelExpansionToggle/PanelExpansionContext";

export type SimulatorPanelRef = {
    filterText: string;
    clearFilter(): void;
    scrollToBottom(): void;
}

export type SimulatorPanelProps = {
    title: ReactNode;
    tip?: ReactNode;
    primaryControl?: ReactNode;
    expandable?: boolean;
    empty?: boolean;
    emptyContent?: ReactNode;
    name: string;
    actions?: (ByzzerButtonProps & { include?: boolean })[];
    contentsClassName?: string;
    alwaysScroll?: boolean;
    enableFilter?: boolean;
    onFilterChange?(filterText: string): void;
    filterPlaceholder?: string;
    byzRef?: React.Ref<SimulatorPanelRef>
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator-panel';

export function SimulatorPanel({
                                   className,
                                   children,
                                   name,
                                   title,
                                   tip,
                                   primaryControl,
                                   expandable = true,
                                   empty = false,
                                   alwaysScroll = true,
                                   enableFilter = false,
                                   filterPlaceholder = 'Filter Values',
                                   onFilterChange,
                                   emptyContent,
                                   actions,
                                   byzRef,
                                   ...props
                               }: SimulatorPanelProps) {

    const {activePanel} = usePanelExpansion();
    const [filterText, setFilterText] = useState<string>('');
    const contentRef = useRef<HTMLElement>(null);

    useImperativeHandle(byzRef, () => ({
        get filterText() {
            return filterText
        },
        clearFilter() {
            setFilterText('');
            onFilterChange?.('')
        },
        scrollToBottom() {
            const el = contentRef.current;
            el!.scrollTop = el!.scrollHeight;
        }
    }), []);

    function handleFilterChange({target}: ChangeEvent<HTMLInputElement>) {
        setFilterText(target.value);
        onFilterChange?.(target.value);
    }

    return <div className={classnames(baseClassName, className, {
        [`${baseClassName}--active`]: activePanel === name
    })} {...props}>

        <header className={classnames(`${baseClassName}__header`)}>
            <h1>
                {title}
                {Boolean(tip) && <ByzzerTipIcon tip={tip}/>}
            </h1>
            {Boolean(primaryControl) && (
                <div className={`${baseClassName}__primary-control`}>{primaryControl}</div>
            )}
            {Boolean(enableFilter) && (
                <div className={`${baseClassName}__primary-filter`}>
                    <input onChange={handleFilterChange} value={filterText} placeholder={filterPlaceholder}/>
                </div>
            )}
            {expandable && <PanelExpansionToggle panel={name}/>}
        </header>
        {empty ? (
            <div className={`${baseClassName}__empty-contents`}>
                {emptyContent}
            </div>
        ) : (
            <main ref={contentRef} className={classnames(`${baseClassName}__contents`, {
                [`${baseClassName}__contents--always-scroll`]: alwaysScroll
            })}>
                {children}
            </main>
        )}
        {Boolean(actions?.length) && (
            <footer className={`${baseClassName}__actions`}>
                {/* only render actions that don't have the include flag set to false */}
                {actions!.filter(a => a.include !== false)
                    .map(({include, ...props}, index) => (
                        <ByzzerButton key={index} {...props}/>
                    ))
                }
            </footer>
        )}
    </div>
}

export default SimulatorPanel;