import {SimulatorChartValueConfig, SimulatorConfig, SimulatorInputConfig} from "@/types/types";
import {getNextColor} from "@/utils";
import {undollar, unquote} from "@/utils/format";


export async function importSimulator([file]: FileList): Promise<SimulatorConfig> {

    return new Promise((resolve, reject) => {

        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', ({target}) => {
                const config: any = JSON.parse(target!.result as string);
                // convert old data value format to new
                if (config.dataset) {
                    delete config.dataset;
                }

                if(config.title) {
                    config.displayName = config.title;
                    delete config.title;
                }

                // @ts-ignore
                // this is only used to handle old configs, it can be removed soon
                if ((config.chartConfig?.values?.[0] as SimulatorInputConfig)?.inputCodes) {
                    // @ts-ignore
                    config.chartConfig.values = config.chartConfig.values.map<SimulatorChartValueConfig>((v: SimulatorInputConfig, i) => ({
                        label: v.label,
                        code: v.inputCodes[0],
                        color: getNextColor(i)
                    }))
                }

                delete config.chartConfig?.layout?.plot?.barWidth;

                // this is only used to handle old configs, it can be removed soon
                if (!config.inputGroups && config.inputs) {
                    config.inputGroups = [{
                        title: '',
                        headers: ['', '', ''],
                        inputs: config.inputs
                    }];
                }

                config.inputGroups = config.inputGroups.map(group => ({
                    layout: 'label-input3x',
                    title: group.label,
                    ...group,
                    label: undefined
                }))

                config.tableConfigs ??= [];

                config.tableConfigs.forEach(config => {
                    config.data = config.data.map((row, r) => row.map((value, c) => unquote(undollar(value))))
                })

                delete config.inputs;

                resolve(config);
            });
            reader.addEventListener('error', reject);
            reader.readAsText(file);
        } else {
            reject(new Error('no file'));
        }
    });
}

export function saveSimulatorAsFile(simulator: SimulatorConfig) {

    const contents = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(simulator));
    const link = document.createElement('a');
    link.setAttribute("href", contents);
    link.setAttribute("download", `${(simulator.displayName || 'simulator').replace(/[^\w\d]/g, '_')}.simulator.json`);
    link.click();
}