import './SimulatorInputGroupEditor.scss';
import React, {ChangeEvent, useImperativeHandle} from "react";
import classnames from "classnames";
import {ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerSelect, ByzzerSelectOption} from "@byzzer/ui-components";
import {SimulatorInputConfig, SimulatorInputGroup, SimulatorInputGroupLayout} from "@/types/types";
import {InputRowEditor} from './InputRowEditor';

export type SimulatorInputGroupEditorRef = {
    addInput(): void;
}

export type SimulatorInputGroupEditorProps = {
    name?: string;
    value: SimulatorInputGroup;
    onChange?: ByzzerChangeEventHandler<SimulatorInputGroup>;
    byzRef?: React.Ref<SimulatorInputGroupEditorRef>
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const layoutOptions: ByzzerSelectOption[] = [{
    display: '2 Column',
    value: 'label-input-label-input'
}, {
    display: 'Label And 1 Input',
    value: 'label-input'
}, {
    display: 'Label And 2 Inputs',
    value: 'label-input2x'
}, {
    display: 'Label And 3 Inputs',
    value: 'label-input3x'
},]
const columnCountsByLayout: Record<SimulatorInputGroupLayout, number> = {
    "label-input": 1,
    "label-input-label-input": 1,
    "label-input2x": 2,
    "label-input3x": 3,
}

const baseClassName = 'simulator-input-group-editor';

export function SimulatorInputGroupEditor({className, value, name, onChange, byzRef, ...props}: SimulatorInputGroupEditorProps) {

    const includeHeaders = ['label-input3x', 'label-input2x'].includes(value.layout!);
    const columnCount = columnCountsByLayout[value.layout!];
    const {headers = ['', '', ''], inputs} = value;

    useImperativeHandle(byzRef, () => ({
        addInput() {

            onChange?.({
                name,
                value: {
                    ...value,
                    inputs: [...inputs, {
                        label: '',
                        inputCodes: ['','','',]
                    }]
                }
            });
        }
    }), [value, onChange])

    function handleInputConfigChange(e: ByzzerChangeEvent<SimulatorInputConfig>): void {

        const index = Number(e.name);
        onChange?.({
            name,
            value: {
                ...value,
                inputs: value.inputs.map((c, i) => i === index ? e.value : c)
            }
        });
    }

    function handleInputConfigRemove(indexString: string): void {

        if (!confirm('Are you sure you want to remove this value?')) return;

        const index = Number(indexString);

        onChange?.({
            name,
            value: {
                ...value,
                inputs: value.inputs.filter((_, i) => i !== index)
            }
        });
    }

    function handleHeaderChange({target}: ChangeEvent<HTMLInputElement>): void {

        const index = Number(target.name);
        onChange?.({
            name,
            value: {
                ...value,
                headers: headers?.map((c, i) => i === index ? target.value : c)
            }
        });
    }

    function handleTitleChange({target}: ChangeEvent<HTMLInputElement>) {
        onChange?.({
            name,
            value: {
                ...value,
                title: target.value
            }
        })
    }

    function handleLayoutChange(e: ByzzerChangeEvent<SimulatorInputGroupLayout>) {

        onChange?.({
            name,
            value: {
                ...value,
                layout: e.value
            }
        })
    }

    return <div className={classnames(baseClassName, className)} {...props}>

        <div className={`${baseClassName}__settings`}>
            <input className={`${baseClassName}__title`}
                   placeholder={'Enter an optional title.'}
                   value={value.title}
                   onChange={handleTitleChange}/>
            <ByzzerSelect className={`${baseClassName}__layout`}
                          name={'layout'}
                          options={layoutOptions}
                          value={value.layout}
                          allowClear={false}
                          onChange={handleLayoutChange as any}/>
        </div>
        <div className={classnames(`${baseClassName}__inputs`, `${baseClassName}__inputs--${value.layout}`)}>
            {includeHeaders && (<>
                <div/>
                {Array.from({length: columnCount}).map((_, index) => (
                    <input className={`${baseClassName}__column-header-input`}
                           key={index}
                           placeholder={'Column Header'}
                           name={String(index)}
                           type={'text'}
                           value={headers[index]} onChange={handleHeaderChange}/>
                ))}
                <div/>
            </>)}
            {inputs.map((config, index) => (
                <InputRowEditor key={index}
                                layout={value.layout}
                                name={String(index)}
                                value={config}
                                onChange={handleInputConfigChange}
                                onRemove={handleInputConfigRemove}/>
            ))}
        </div>
    </div>
}

export default SimulatorInputGroupEditor;