import './CdnPreview.scss';
import React, {useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {useInView} from "framer-motion";
import blank from "@images/blank.png";

const baseClassName = 'cdn-preview';

type CdnPreviewProps = {
    filename: string;
    src: string;
    selected?: boolean;
    onSelect(url: string): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onSelect'>>;

function CdnPreview({className, filename, src, selected, onClick, onSelect, ...props}: CdnPreviewProps) {

    const [invalid, setInvalid] = useState<boolean>(false);
    const [shouldRender, setShouldRender] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);
    const inView = useInView(ref);
    const timeout = useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            setShouldRender(inView)
        }, 500);
    }, [inView]);

    function handleClick(e: React.MouseEvent<HTMLDivElement>): void {
        if(!invalid) {
            onSelect(src);
        }
        onClick?.(e);
    }

    function handleError() {
        setInvalid(true);
    }

    function handleSuccess() {
        setInvalid(false);
    }

    return (
        <div ref={ref}
             className={classnames(`${baseClassName}`, className, {
                 [`${baseClassName}--selected`]: selected,
                 [`${baseClassName}--invalid`]: invalid
             })}
             onClick={handleClick}
             {...props}>
            <div className={`${baseClassName}__preview`}>
                <img className={classnames(`${baseClassName}__image`, {
                    [`${baseClassName}__image--hidden`]: invalid || !shouldRender
                })}
                     src={shouldRender ? src : blank}
                     onError={handleError} onLoad={handleSuccess}/>
            </div>
            <div className={`${baseClassName}__filename`}>
                {filename}
            </div>
        </div>
    );
}

export default CdnPreview;