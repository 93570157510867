import './SimulatorLeverEditorPanel.scss';
import React, {useEffect, useRef, useState} from "react";
import classnames from "classnames";
import SimulatorLeverEditor from "@/components/SimulatorDataSetup/panels/SimulatorLeverEditor";
import {SimulatorCalculation, SimulatorConfig, SimulatorLeverConfig} from "@/types/types";
import {ByzzerButton, ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerTipIcon} from '@byzzer/ui-components'
import PanelExpansionToggle from "@/components/PanelExpansionToggle/PanelExpansionToggle";
import {SimulatorPanel, SimulatorPanelRef} from "@/components/SimulatorPanel";

export type SimulatorLeverEditorPanelProps = {
    name?: string;
    simulator: SimulatorConfig;
    onApply?: ByzzerChangeEventHandler<SimulatorLeverConfig[]>;
    onChange?: ByzzerChangeEventHandler<SimulatorLeverConfig[]>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'simulator-lever-editor-panel';

export function SimulatorLeverEditorPanel({
                                              className,
                                              name,
                                              simulator,
                                              onApply,
                                              onChange,
                                              ...props
                                          }: SimulatorLeverEditorPanelProps) {

    const [leverConfigs, setLeverConfigs] = useState<SimulatorLeverConfig[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const panelRef = useRef<SimulatorPanelRef>(null);

    useEffect(() => {
        const {leverConfigs = []} = simulator ?? {};
        setLeverConfigs(leverConfigs);
    }, [simulator?.leverConfigs]);

    function addLever(): void {
        panelRef.current?.clearFilter();
        setLeverConfigs(configs => [...configs, {
            code: '',
            label: '',
            datatype: 'decimal'
        }]);
        setTimeout(() => panelRef.current?.scrollToBottom(), 100);
    }

    function handleLeverConfigChange(e: ByzzerChangeEvent<SimulatorLeverConfig>): void {
        const index = Number(e.name);
        setLeverConfigs(configs => configs.map((formula, i) => index !== i ? formula : e.value));
        onChange?.({
            name,
            value: leverConfigs.map((formula, i) => index !== i ? formula : e.value)
        })
    }

    function handleLeverConfigRemove(name: string): void {

        if (!confirm('Are you sure you want to remove this value?')) return;

        const index = Number(name);
        setLeverConfigs(configs => configs.filter((formula, i) => index !== i));
        onChange?.({
            name,
            value: leverConfigs.filter((formula, i) => index !== i)
        })
    }

    function handleApplyClick(): void {
        onApply?.({
            name,
            value: leverConfigs
        });
    }

    return <SimulatorPanel className={classnames(baseClassName, className)}
                           title={'Create Your Levers'}
                           name={'leverEditor'}
                           tip={<>
                               <p>Levers give users of your simulator the ability to change values and see the
                                   affects.</p>
                               <p>You must choose a type and set a variable name in order to use the lever in
                                   formula</p>
                               <p>You can optionally set a formula that will recalculate the lever's value when an an
                                   associated lever changes.</p>
                               <p>Try out your levers in the "Create Your Calculations" and "Test Your Levers"
                                   panels.</p>
                           </>}
                           empty={!leverConfigs.length}
                           emptyContent={'You haven\'t created any Levers.'}
                           actions={[
                               {onClick: addLever, label: `Add Lever`},
                               {include: Boolean(onApply), onClick: handleApplyClick, label: `Apply`},
                           ]}
                           enableFilter={true}
                           onFilterChange={setFilterText}
                           filterPlaceholder={'Filter the Levers by key.'}
                           byzRef={panelRef}
                           {...props}>

        {leverConfigs.map((config, index) => {
            // levers are managed in an array so we have to always reference them by their index
            // we have to use this log instead of filtering so we don't lose track the original index
            if(filterText && !config.code.toLowerCase().includes(filterText.toLowerCase())) return <></>;

            return <SimulatorLeverEditor key={index}
                                  name={String(index)}
                                  value={config}
                                  onChange={handleLeverConfigChange}
                                  onRemove={handleLeverConfigRemove}/>
        })}
    </SimulatorPanel>
}

export default SimulatorLeverEditorPanel;