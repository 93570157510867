import './SimulatorChartConfigEditor.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {ByzChart, ByzChartConfig, ByzChartData, ByzChartType} from "@/components/visualizations/ByzChart";
import {ByzzerButton, ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerSelect} from "@byzzer/ui-components";
import {SimulatorChartConfig, SimulatorChartValueConfig, SimulatorConfig} from "@/types/types";
import {chartTypeOptions} from "@/ChartTypeOptions";
import SimulatorChartValueEditor from "@/components/SimulatorLayoutEditor/panels/SimulatorChartValueEditor";
import {getNextColor} from "@/utils";
import {PanelExpansionToggle} from "@/components/PanelExpansionToggle";
import {SimulatorPanel} from "@/components/SimulatorPanel";

export type SimulatorChartConfigEditorProps = {
    simulator: SimulatorConfig;
    name?: string;
    onApply?: ByzzerChangeEventHandler<SimulatorChartConfig>;
    onChange?: ByzzerChangeEventHandler<SimulatorChartConfig>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'simulator-chart-config-editor';

export function SimulatorChartConfigEditor({className, name, simulator, onApply, onChange, ...props}: SimulatorChartConfigEditorProps) {

    const [chartType, setChartType] = useState<ByzChartType>('bar');
    const [mode, setMode] = useState<'preview' | 'edit'>('edit');
    const [chartedValues, setChartedValues] = useState<SimulatorChartValueConfig[]>([]);
    const [layout, setLayout] = useState<ByzChartConfig>();
    const [chartData, setChartData] = useState<ByzChartData>({
        series: []
    });

    useEffect(() => {
        const {values, layout, type} = simulator.chartConfig;
        setChartedValues(values);
        setChartType(type);
        setLayout(layout);
    }, [simulator.chartConfig])

    useEffect(() => {
        setChartData(({
            series: [{
                values: chartedValues.map((v, i) => i + 1),
                styles: chartedValues.map((v) => ({
                    backgroundColor: v.color
                }))
            }],
            scaleX: {
                labels: chartedValues.map(v => v.label)
            }
        }))
    }, [chartedValues]);

    function addChartedValue() {
        setChartedValues(values => [...values, {
            label: '',
            code: '',
            color: getNextColor(values.length)
        }]);

    }

    function handleChartedValueChange(e: ByzzerChangeEvent<SimulatorChartValueConfig>): void {

        const index = Number(e.name);
        setChartedValues(configs => configs.map((c, i) => i === index ? e.value : c));
        onChange?.({
            name,
            value: {
                type: chartType,
                layout,
                values: chartedValues.map((c, i) => i === index ? e.value : c)
            }
        });
    }

    function handleChartedValueRemove(name: string): void {

        if (!confirm('Are you sure you want to remove this value?')) return;

        const index = Number(name);
        setChartedValues(configs => configs.filter((c, i) => i !== index));
        onChange?.({
            name,
            value: {
                type: chartType,
                layout,
                values: chartedValues.filter((c, i) => i !== index)
            }
        });
    }

    function handleChartTypeChange(e: ByzzerChangeEvent<ByzChartType>): void {
        setChartType(e.value);
        onChange?.({
            name,
            value: {
                type: e.value,
                layout,
                values: chartedValues
            }
        });
    }

    function handleApply(): void {
        onApply?.({
            name,
            value: {
                values: chartedValues,
                type: chartType,
                layout
            }
        })
    }

    return <SimulatorPanel className={classnames(baseClassName, className)}
                           name={'charts'}
                           title={'Chart'}
                           actions={[
                               {onClick: addChartedValue, label: 'Add Value'},
                               {include: Boolean(onApply), onClick: handleApply, label: 'Apply'},
                           ]}
                           tip={<>
                               <p>Use this panel to add Levers, Calculations and Dataset values to your chart.</p>
                               <p>Clicking the color next to the input allows you to change the color of the bar.</p>
                           </>}
                           alwaysScroll={false}
                           {...props}>
        <div className={`${baseClassName}__chart`}>
            <ByzzerSelect className={`${baseClassName}__selector`}
                          allowClear={false}
                          options={chartTypeOptions}
                          onChange={handleChartTypeChange as any}
                          value={chartType}/>

            <ByzChart className={`${baseClassName}__chart-preview`} type={chartType} data={chartData} config={layout}/>
        </div>
        <div className={`${baseClassName}__values`}>
            {chartedValues.map((config, i) => (
                <SimulatorChartValueEditor key={i}
                                           name={String(i)} simulator={simulator} value={config}
                                           onChange={handleChartedValueChange}
                                           onRemove={handleChartedValueRemove}/>
            ))}
            {chartedValues.length === 0 && (
                <div className={'empty-state'}>You haven't added any values to the chart.</div>
            )}
        </div>
    </SimulatorPanel>
}

export default SimulatorChartConfigEditor;