import './SimulatorLeverEditor.scss';
import React, {ChangeEvent, useState, KeyboardEvent, useEffect} from "react";
import classnames from "classnames";
import {
    ByzzerButton,
    ByzzerChangeEvent,
    ByzzerChangeEventHandler, ByzzerCheckableChangeEvent, ByzzerCheckbox,
    ByzzerMultiSelect,
    ByzzerSelect, ByzzerTipIcon
} from "@byzzer/ui-components";
import {SimulatorDatatype, simulatorDatatypes, SimulatorLeverConfig} from "@/types/types";
import {useSimulator} from "@/pages/Simulator";
import {ControlledInput} from "@/components/ControlledInput";

export type SimulatorLeverEditorProps = {
    name: string;
    value: SimulatorLeverConfig;
    onChange: ByzzerChangeEventHandler<SimulatorLeverConfig>;
    onRemove?(name: string): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'simulator-lever-editor';

export function SimulatorLeverEditor({className, name, value, onChange, onRemove, ...props}: SimulatorLeverEditorProps) {

    const {variableOptions} = useSimulator();
    const [internalValue, setInternalValue] = useState<SimulatorLeverConfig>({
        code: ''
    })

    useEffect(() => {
        setInternalValue(value);
    }, [value])

    function handleKeyDown({key}: KeyboardEvent): void {
        if(key == 'Enter') {
            applyChanges();
        }
    }

    function applyChanges() {

        onChange?.({
            name,
            value: internalValue
        });
    }

    function handleValueChange({target}: ChangeEvent<HTMLInputElement>) {

        if (target.name === 'code') {
            // only allow letters, numbers and underscores for codes
            target.value = target.value.replace(/\s/g, '_').replace(/[^\w_]/, '');
        }
        setInternalValue(value => ({
            ...value,
            [target.name]: target.value
        }));
    }

    function handleCheckboxChange(e: ByzzerCheckableChangeEvent<string>) {

        onChange?.({
            name,
            value: {
                ...value,
                [e.name!]: e.checked
            }
        })
    }

    function handleSelectChange(e: ByzzerChangeEvent<SimulatorDatatype | string | string[]>): void {
        onChange?.({
            name,
            value: {
                ...value,
                [e.name!]: e.value
            }
        })
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__values`}>
            <ByzzerSelect className={`${baseClassName}__type`}
                          name="datatype" options={simulatorDatatypes}
                          value={internalValue.datatype}
                          onChange={handleSelectChange as any}
                          allowClear={false}/>
            <ControlledInput className={classnames(`${baseClassName}__input`, `${baseClassName}__name`)}
                   name="label"
                   type={'text'}
                   value={internalValue.label}
                   placeholder={'Label'}
                   onKeyDown={handleKeyDown}
                   onBlur={applyChanges}
                   onChange={handleValueChange}/>
            <label className={classnames(`${baseClassName}__input`, `${baseClassName}__code`, 'simulator__code-input')}>
                <ControlledInput name="code"
                       type={'text'}
                       value={internalValue.code}
                       placeholder={'Enter a key.'}
                       onKeyDown={handleKeyDown}
                       onBlur={applyChanges}
                       onChange={handleValueChange}/>
            </label>
            <label
                className={classnames(`${baseClassName}__input`, `${baseClassName}__formula`, 'simulator__formula-input')}>
                <ControlledInput name="formula"
                       type={'text'}
                       value={internalValue.formula}
                       placeholder={'Enter an optional default formula.'}
                       onKeyDown={handleKeyDown}
                       onBlur={applyChanges}
                       onChange={handleValueChange}/>
            </label>
            <ByzzerMultiSelect className={`${baseClassName}__reset-codes`}
                               name="resetCodes" options={variableOptions}
                               placeholder={'Choose the levers to reset.'}
                               label={<>
                                   Reset Levers <ByzzerTipIcon
                                   tip={<>
                                       <p>All of the levers in this list will be reset to their default value when this lever is edited.</p>
                                       <p>Do not include levers that already have a reference to this lever in their formula.</p>
                                   </>}/>
                               </>}
                               value={internalValue.resetCodes}
                               onChange={handleSelectChange as any}
                               allowClear={false}/>
            <ByzzerCheckbox className={`${baseClassName}__excluded`}
                            name="excludeFromSavedSims"
                            checked={internalValue.excludeFromSavedSims}
                            onChange={handleCheckboxChange}
                            label={<>
                                Exclude From User Saved Simulations <ByzzerTipIcon
                                className={`${baseClassName}__excluded-tip`}
                                tip={<>
                                    <p>When checked this value will not be included in a user's Saved Simulation.</p>
                                    <p>Use this for values that can't be saved for legal reasons.</p>
                                </>}/>
                            </>}/>
            <ByzzerCheckbox className={`${baseClassName}__excluded`}
                            name="persist"
                            checked={internalValue.persist}
                            onChange={handleCheckboxChange}
                            label={<>
                                Maintain Calculated Value <ByzzerTipIcon
                                className={`${baseClassName}__excluded-tip`}
                                tip={<>
                                    <p>When checked the value will only recalculate if one of it's $$ dependencies is updated</p>
                                </>}/>
                            </>}/>
        </div>
        <ByzzerButton onClick={() => onRemove?.(name)}
                      type={'remove'}
                      iconType={'delete'}
                      tipDelay={[250, 0]}
                      tipLocation={'bottom'}
                      tip={'Remove'}/>
    </div>
}

export default SimulatorLeverEditor;