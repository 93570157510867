import './ImagePickerCDN.scss';
import React, {CSSProperties, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {ByzzerButton, ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerTextInput} from "@byzzer/ui-components";
import {getCDNImages} from "@/services/api.service";
import {cssUrlVar} from "@/utils/cssUrlVar";
import {useInView} from "framer-motion";
import {ByzzerMask} from "@/components/ByzzerMask";
import blank from '@/images/blank.png';
import CdnPreview from "@/components/ImagePicker/CdnPreview";

export type ImagePickerCDNProps = {
    onApply: ByzzerChangeEventHandler<string>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'image-picker-cdn';

export function ImagePickerCDN({className, onApply, ...props}: ImagePickerCDNProps) {

    const [loading, setLoading] = useState<boolean>(false);
    const [images, setImages] = useState<any[]>([]);
    const [selectedUrl, setSelectedUrl] = useState<string>('');
    const [filterText, setFilterText] = useState<string>('');
    const [filteredImages, setFilteredImages] = useState<any[]>([])

    useEffect(() => {
        ;(async () => {
            try {
                setLoading(true);
                const cdnImages = (await getCDNImages()).sort((a, b) => a.name.localeCompare(b.name, 'en-US', {sensitivity:'base'}))
                setImages(cdnImages);
                setFilteredImages(cdnImages);
            } finally {
                setLoading(false)
            }
        })();
    }, []);

    function handleFilterChange(e: ByzzerChangeEvent<string>) {
        const text = e.value;
        setFilterText(text);
        setFilteredImages(images.filter(v => !text || v.name.toLowerCase().includes(text.toLowerCase())))
    }

    function handleApply() {

        if (!selectedUrl) return;

        onApply({
            value: selectedUrl
        })
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <main className={`${baseClassName}__content`}>
            <ByzzerTextInput className={`${baseClassName}__filter`}
                             placeholder={'Search images by name.'}
                             value={filterText} onChange={handleFilterChange}/>
            <div className={`${baseClassName}__images`}>
                <ByzzerMask loading={loading}>Loading Images</ByzzerMask>
                {filteredImages.map(({name, url}) => (
                    <CdnPreview key={url}
                               onSelect={setSelectedUrl}
                               selected={url === selectedUrl}
                               filename={name} src={url}/>
                ))}
            </div>
        </main>
        <footer className={`${baseClassName}__actions`}>
            <ByzzerButton onClick={handleApply} disabled={!selectedUrl}>Apply</ByzzerButton>
        </footer>
    </div>
}

// style={cssUrlVar('image', renderImage ? src : '')}>
export default ImagePickerCDN;