import './SimulatorCalculationsEditorPanel.scss';
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {evaluateFormula, InvalidFormulaError, validateFormula, ValidationErrors} from "@/services/formula.service";
import {
    ByzzerButton,
    ByzzerChangeEvent,
    ByzzerChangeEventHandler,
    ByzzerSelect,
    ByzzerTipIcon
} from "@byzzer/ui-components";
import {
    SimulatorCalculation,
    SimulatorConfig,
    SimulatorDatatype,
    simulatorDatatypes,
    SimulatorValues
} from "@/types/types";
import {uniq} from 'lodash';
import PanelExpansionToggle from "@/components/PanelExpansionToggle/PanelExpansionToggle";
import {SimulatorPanel, SimulatorPanelRef} from "@/components/SimulatorPanel";
import {useOutletContext} from "react-router-dom";
import {useSimulator} from "@/pages/Simulator";

export type SimulatorFormulaPanelProps = {
    name?: string;
    simulator: SimulatorConfig;
    values: SimulatorValues;
    onApply?: ByzzerChangeEventHandler<SimulatorCalculation[]>;
    onChange?: ByzzerChangeEventHandler<SimulatorCalculation[]>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

type CalculationResult = {
    output: string | number | undefined;
    errors?: ValidationErrors;
}

const baseClassName = 'simulator-calculations-editor-panel';

export function SimulatorCalculationsEditorPanel({
                                                     className,
                                                     name,
                                                     simulator,
                                                     onApply,
                                                     onChange,
                                                     values,
                                                     ...props
                                                 }: SimulatorFormulaPanelProps) {

    const {variables} = useSimulator();
    const [calculations, setCalculations] = useState<SimulatorCalculation[]>([]);
    const [results, setResults] = useState<CalculationResult[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const panelRef = useRef<SimulatorPanelRef>(null);

    useEffect(() => {
        setCalculations(simulator.calculations);
    }, [simulator]);

    useEffect(validateAndEvaluateFormulas, [values, variables, calculations]);

    function handleFormulaChange(e: ByzzerChangeEvent<SimulatorCalculation>): void {
        const index = Number(e.name);
        setCalculations(calculations => calculations.map((calculation, i) => index !== i ? calculation : e.value));
        onChange?.({
            name,
            value: calculations.map((calculation, i) => index !== i ? calculation : e.value)
        });
    }

    function handleFormulaRemove(name: string): void {

        if (!confirm('Are you sure you want to remove this value?')) return;

        const index = Number(name);
        setCalculations(calculations => calculations.filter((_, i) => index !== i));
        onChange?.({
            name,
            value: calculations.filter((_, i) => index !== i)
        });
    }

    function validateAndEvaluateFormulas(): void {

        const calculationFormulas: Record<string, string> = calculations.reduce((result, {code, formula}) => ({
            ...result,
            [code]: formula
        }), {});

        setResults(calculations.map(({formula}) => {

            let validation = validateFormula(formula, variables);
            if(validation === true) {
                return {
                    output: evaluateFormula<number>(formula, {
                        ...values,
                        ...calculationFormulas
                    })
                };
            } else {
                return {
                    output: validation.badSyntax ? 'INVALID FORMULA' : 'INVALID FUNCTIONS OR VALUES',
                    errors: validation,
                };
            }
        }));
    }

    function addCalculation() {
        panelRef.current?.clearFilter();
        setCalculations(formulas => [...formulas, {
            code: '',
            formula: '',
            datatype: 'decimal'
        }]);
        setTimeout(() => panelRef.current?.scrollToBottom(), 100);
    }

    function handleApply() {
        onApply?.({
            name,
            value: calculations
        })
    }

    return <SimulatorPanel className={classnames(baseClassName, className)}
                           title={'Create Your Calculations'}
                           name={'calculationEditor'}
                           tip={<>
                               <p>Calculations are excel style formulas that produce read only values that be used in
                                   your simulator.</p>
                               <p>Calculations can use Dataset values, Lever values and even other Calculations. To
                                   reference the values just prefix the key with a $.</p>
                               <p>For example, to add the levers val1, val2 and val3 together use:
                                   SUM($val1,$val2,$val3)</p>
                           </>}
                           empty={!calculations.length}
                           emptyContent={`You haven't created any Calculations.`}
                           actions={[
                               {onClick: addCalculation, label: `Add Calculation`},
                               {include: Boolean(onApply), onClick: handleApply, label: 'Apply'},
                           ]}
                           enableFilter={true}
                           onFilterChange={setFilterText}
                           filterPlaceholder={'Filter calculations by key.'}
                           byzRef={panelRef}
                           {...props}>

        {calculations.map((formula, index) => {
            // formulas are managed in an array so we have to always reference them by their index
            // we have to use this log instead of filtering so we don't lose track the original index
            if (filterText && !formula.code.toLowerCase().includes(filterText.toLowerCase())) return <></>;

            return <SimulatorCalculationInput key={index}
                                              name={String(index)} value={formula}
                                              result={results[index]}
                                              onRemove={handleFormulaRemove}
                                              onChange={handleFormulaChange}/>
        })}
    </SimulatorPanel>
}

type SimulatorFormatInputProps = {
    name?: string;
    value: SimulatorCalculation;
    result: CalculationResult;
    onChange: ByzzerChangeEventHandler<SimulatorCalculation>;
    onRemove?(name: string): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

function SimulatorCalculationInput({name, value, result, onChange, onRemove}: SimulatorFormatInputProps) {

    const {formula, code} = value;
    const {errors, output} = result ?? {};

    function handleChange({target}: ChangeEvent<HTMLInputElement>): void {
        if (target.name === 'code') {
            // only allow letters, numbers and underscores for codes
            target.value = target.value.replace(/\s/g, '_').replace(/[^\w\d_]/, '');
        }
        onChange({
            name,
            value: {
                ...value,
                [target.name]: target.value
            }
        });
    }

    function handleTypeChange(e: ByzzerChangeEvent<SimulatorDatatype>) {
        onChange({
            name,
            value: {
                ...value,
                [e.name!]: e.value
            }
        });
    }

    return <div className={`${baseClassName}__calculation`}>
        <div className={`${baseClassName}__values`}>
            <ByzzerSelect className={`${baseClassName}__type`}
                          name="datatype" options={simulatorDatatypes}
                          value={value.datatype}
                          onChange={handleTypeChange as any}
                          allowClear={false}/>

            <label className={classnames(`${baseClassName}__input`, `${baseClassName}__code`, 'simulator__code-input')}>
                <input type={'text'}
                       name={'code'}
                       placeholder={'Enter a key.'}
                       value={code}
                       onChange={handleChange}/>
            </label>
            <label
                className={classnames(`${baseClassName}__input`, `${baseClassName}__formula`, 'simulator__formula-input')}>
                <input type={'text'}
                       placeholder={'example: SUM($value1, $value2)'}
                       name={'formula'}
                       value={formula}
                       onChange={handleChange}/>
            </label>
        </div>
        <ByzzerButton onClick={() => onRemove?.(name!)}
                      type={'remove'}
                      iconType={'delete'}
                      tipDelay={[250, 0]}
                      tipLocation={'bottom'}
                      tip={'Remove'}/>
        {Boolean(errors) ? (
            <ul className={`${baseClassName}__issues`}>
                {errors!.badCharacters.length && <li className={`${baseClassName}__invalid-function`}>
                    ^ cannot be used. Replace it with the POWER function
                </li>}
                {uniq(errors!.invalidFunctions).sort().map(name => (
                    <li className={`${baseClassName}__invalid-function`} key={name}>
                        function {name} is not supported
                    </li>))}
                {uniq(errors!.invalidVariables).sort().map(name => (
                    <li className={`${baseClassName}__invalid-variable`} key={name}>
                        variable {name} is not defined
                    </li>))}
                {uniq(errors!.missingPrefixes).sort().map(name => (
                    <li className={`${baseClassName}__invalid-variable`} key={name}>
                        variable {name} must be prefixed with "$"
                    </li>))}
            </ul>
        ) : (
            <div className={`${baseClassName}__result`}>result: {output ?? 'N/A'}</div>
        )}
    </div>
}


export default SimulatorCalculationsEditorPanel;