import './SimulatorOutputTables.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {SimulatorDatatype, SimulatorTableConfig, SimulatorValues} from "@/types/types";
import {FormattedValue} from "@/components/SimulatorPreview/FormattedValue";
import {ByzzerButton} from "@byzzer/ui-components";
import XLSX from 'xlsx-js-style';
import {act} from "react-dom/test-utils";
import {rawValueToDisplay} from "@/utils";
import {generateSpreadsheet} from "@/services/spreadsheet.service";
import {useSimulator} from "@/pages/Simulator";

export type SimulatorOutputTablesProps = {
    tables?: SimulatorTableConfig[];
    values: Record<string, number | undefined>;
    datatypeMap: Record<string, SimulatorDatatype>;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator-output-tables';

export function SimulatorOutputTables(
    {
        className,
        datatypeMap,
        tables,
        values,
        ...props
    }: SimulatorOutputTablesProps) {

    const {simulator} = useSimulator();
    const [activeTable, setActiveTable] = useState<SimulatorTableConfig | undefined>();

    useEffect(() => {
        setActiveTable(tables?.[0]);
    }, [tables]);

    async function handleDownload() {

        if (!activeTable) return;
        generateSpreadsheet({
            cells: [
                [{
                    value: 'Impacts',
                    colSpan: activeTable.columns.length,
                    formatting: {
                        bold: true,
                        hAlign: 'center',
                        bgColor: '#efefef',
                        borderBottom: true,
                    }
                }],
                activeTable.columns.map(c => ({
                    value: c.title,
                    formatting: {
                        bold: true,
                        hAlign: 'right'
                    }
                })),
                ...activeTable.data.map(row => row.map((cell, i) => i === 0 ? cell : `{$${cell}}`))
            ]
        }, values, datatypeMap, {}, simulator)
        // STEP 1: Create a new workbook

    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__tabs`}>
            {tables?.map((table, i) => (
                <div key={i} className={classnames(`${baseClassName}__tab`, {
                    [`${baseClassName}__tab--active`]: activeTable === table
                })} onClick={() => setActiveTable(table)}>
                    <h2>{table.title}</h2>
                </div>
            ))}
        </div>
        <div className={`${baseClassName}__data`}>
            {Boolean(activeTable) && (
                <table>
                    <thead>
                    <tr>
                        {activeTable?.columns.map((col, i) => <th key={i}>{col.title}</th>)}
                    </tr>
                    </thead>
                    <tbody>
                    {activeTable?.data.map((row, i) => (
                        <tr key={i}>
                            {row.map((code, i) => (
                                <td key={i}>
                                    {Boolean(values[code]) ? (
                                        <FormattedValue type={datatypeMap[code]} value={values[code]}/>
                                    ) : (
                                        code
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
        <div className={`${baseClassName}__actions`}>
            <ByzzerButton onClick={handleDownload}
                          disabled={!activeTable}
                          tip={<>
                              <p>The spreadsheet will generated completely in the browser.</p>
                              <p>Currently it's using the content of the active tab. But soon it will be configurable
                                  just
                                  like everything else.</p>
                          </>}
                          label={'Download As XLSX'}/>
        </div>
    </div>
}

export default SimulatorOutputTables;