import {Navigate, RouteObject} from "react-router-dom";
import {useUser} from "@/contexts";
import React, {Children, forwardRef, ReactElement, useImperativeHandle} from "react";
import {User} from 'firebase/auth';

export interface AppRouteProps extends React.HTMLAttributes<unknown> {
    route: RouteConfig; // todo: define the structure of route
}

export type AppRouteRef = {
    route: RouteConfig;
    component: ReactElement;
}
export const AppRoute: React.FC<AppRouteProps> = forwardRef<AppRouteRef, AppRouteProps>(
    ({ children, route, ...props }, ref) => {

        if (route.title) {
            document.title = route.title;
        }
        useImperativeHandle(ref, () => ({
            route,
            component: route.element,
        }));
        //
        // return Children.map(children, (child, index) => {
        const child = Children.toArray(children)[0];
        if (React.isValidElement(child)) {
            return React.cloneElement(child, props);
        } else {
            return <>{child}</>;
        }
        // });
    }
);
AppRoute.displayName = 'AppRoute'

export type RouteMetadata = Record<string, unknown>;

export type RouteGuardFunction = (meta: RouteMetadata, user: User | null) => string | undefined;

export type RouteGuardOptions = {
    guard?: RouteGuardFunction;
    element: React.JSX.Element,
    meta: RouteMetadata
}

export function RouteGuard({ guard, element, meta = {}, ...props }: RouteGuardOptions) {

    const { user } = useUser();

    if (meta?.requireAuth && !user) {
        return <Navigate to={'/auth/sign_in'} />;
    }

    const redirect = guard?.(meta, user);
    if (redirect) {
        return <Navigate to={redirect} replace />;
    }

    return React.cloneElement(element, props);
}
export type RouteConfig = {
    path: string;
    element: ReactElement;
    title?: string;
    aliases?: string[];
    children?: RouteConfig[];
    meta?: RouteMetadata;
    guard?: RouteGuardFunction;
    redirect?: string;
    default?: boolean
}
export function toRoute(config: RouteConfig, parent?: RouteConfig): RouteObject[] {

    const { path, aliases = [], children, meta = {}, guard, redirect, ...options } = config;
    let {element} = config;

    config.title = config.title ?? parent?.title;

    const paths: { path: string, element: JSX.Element }[] = [];

    if (redirect) {
        return [{ path: path, element: <Navigate to={redirect} replace /> }];
    }

    // if this a default path, make "*" and "" aliases
    if (options?.default === true) {
        aliases.push('*', '');
    }

    // create a redirect for each alias
    // todo: make this work for non leaf paths
    aliases.forEach((alias) => paths.push({ path: alias, element: <Navigate to={path} replace /> }));

    if (guard || meta?.requireAuth) {
        element = <RouteGuard guard={guard} element={element} meta={meta} />;
    }

    element = <AppRoute route={config}>{element}</AppRoute>;

    return [...paths, { path, element, children: children?.map((child) => toRoute(child, config)).flat() }];
}

export function buildRoutes(configs: RouteConfig[]): RouteObject[] {

    return configs.map(config => toRoute(config)).flat();
}