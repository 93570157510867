import './SimulatorStaticValueEditorPanel.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {ByzzerButton, ByzzerChangeEvent, ByzzerChangeEventHandler} from "@byzzer/ui-components";
import {SimulatorConfig, SimulatorInputConfig} from "@/types/types";
import {PanelExpansionToggle} from "@/components/PanelExpansionToggle";
import SimulatorRowConfigEditor from "@/components/SimulatorLayoutEditor/panels/InlineInputConfigEditor";
import {SimulatorPanel} from "@/components/SimulatorPanel";

export type SimulatorStaticValueEditorPanelProps = {
    name?: string;
    simulator: SimulatorConfig;
    onChange?: ByzzerChangeEventHandler<SimulatorInputConfig[]>;
    /**
     * Not implemented
     */
    onApply?: ByzzerChangeEventHandler<SimulatorInputConfig[]>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'simulator-static-value-editor-panel';

export function SimulatorStaticValueEditorPanel({className, name, simulator, onChange, onApply, ...props}: SimulatorStaticValueEditorPanelProps) {

    const [staticValues, setStaticValues] = useState<SimulatorInputConfig[]>([]);

    useEffect(() => {
        setStaticValues(simulator.staticValues);
    }, [simulator.staticValues])

    function handleStaticValueChange(e: ByzzerChangeEvent<SimulatorInputConfig>): void {

        const index = Number(e.name);
        onChange?.({
            name,
            value: staticValues.map((c, i) => i === index ? e.value : c)
        });
    }

    function handleStaticValueRemove(name: string): void {

        if (!confirm('Are you sure you want to remove this value?')) return;

        const index = Number(name);
        onChange?.({
            name,
            value: staticValues.filter((c, i) => i !== index)
        });
    }

    function addFixedValue() {
        onChange?.({
            name,
            value: [...staticValues, {
                label: '',
                inputCodes: []
            }]
        });
    }

    return <SimulatorPanel className={classnames(baseClassName, className)}
                           name={`static-values`}
                           title={`Helpful Inputs`}
                           actions={[
                               {onClick: addFixedValue, label: `Add Value`}
                           ]}
                           primaryControl={
                               <input className={`${baseClassName}__title-input`}
                                      type={'text'} placeholder={'Enter an optional title override.'}/>
                           }
                           empty={!staticValues.length}
                           {...props}>

        {staticValues.map((config, i) => (
            <SimulatorRowConfigEditor key={i}
                                      className={`${baseClassName}__value`}
                                      columnCount={1} name={String(i)} simulator={simulator} value={config}
                                      onChange={handleStaticValueChange}
                                      onRemove={handleStaticValueRemove}/>
        ))}
    </SimulatorPanel>
}

export default SimulatorStaticValueEditorPanel;