
export function quote(value: string): string {
    return value ? `'${value.replace(/'/g, `\\'`)}'` : value;
}

export function unquote(value: string): string {
    return value?.replace?.(/^'/,'').replace(/'$/, '').replace(/\\'/, `'`);
}

export function dollar(value: string): string {
    return value ? `$${value}` : value;
}

export function undollar(value: string): string {
    return value?.replace?.(/^\$/,'');
}