import React, { useContext, useEffect, useState } from 'react';
import { openErrorModal } from "@/components/Modal/Modal";
import {User, getAuth, onAuthStateChanged} from 'firebase/auth';

export type UserContextValue = {
    user: User | null;
    refreshUser?: Function;
    envs: string[];
}
const UserContext = React.createContext<UserContextValue>({ user: null, envs: []});
const InitializedContext = React.createContext(false);
const MessagesContext = React.createContext([]);
const EventsContext = React.createContext([]);

export const useUser = () => useContext(UserContext);
export const useInitialized = () => useContext(InitializedContext);

export const UserProvider = ({ children }) => {

    const [contextValue, setContextValue] = useState<UserContextValue>({
        user: null,
        envs: []
    });

    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {

        const unsubscribeToAuthChanges = getAuth().onAuthStateChanged(async (user: User | null) => {
            if (user) {
                try {
                    const { claims } = await user.getIdTokenResult(true);
                    if (claims.albyEnvs) {
                        setContextValue(value => ({
                            ...value,
                            user,
                            envs: claims.albyEnvs
                        }));
                    } else {
                        setContextValue({
                            user: null,
                            envs: []
                        });
                        await getAuth().signOut();
                    }
                } catch (err: any) {
                    switch (err.code as string) {
                        case 'user_not_found':
                            await openErrorModal({
                                title: `Account Not Found`,
                                content: (
                                    <>
                                        <p>We didn't find an account matching your email address.</p>
                                        <p>Please make sure you entered it correctly.</p>
                                    </>
                                ),
                            });
                            //TODO: Should the user be deleted from authentication in this case?
                            await getAuth().signOut();
                            break;
                        case 'network_error':
                            await openErrorModal({
                                title: `Communication Error`,
                                content: (
                                    <>
                                        <p>We can't reach the Byzzer servers at this time.</p>
                                        <p>Please make sure you're connected to the internet.</p>
                                    </>
                                ),
                                // closeButtonText: 'Try Again',
                            });
                            return window.location.reload();
                    }
                }
            } else {
                setContextValue({
                    user: null,
                    envs: []
                })
            }

            setInitialized(true);
        });

        return unsubscribeToAuthChanges;
    }, []);

    async function refreshUser() {
        //setUser(await getUser(true));
    }

    return (
        <InitializedContext.Provider value={initialized}>
            <UserContext.Provider value={contextValue}>
                {children}
            </UserContext.Provider>
        </InitializedContext.Provider>
    );
};
