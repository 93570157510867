import {ReactNode} from "react";
import {ByzChartConfig, ByzChartType} from "@/components/visualizations/ByzChart";


export const SIMULATOR_DATETYPES = [
    'decimal',
    'integer',
    'percent',
    'currency'
] as const;

export type SimulatorDatatype = typeof SIMULATOR_DATETYPES[number];

export const simulatorDatatypes: SimulatorDatatype[] = SIMULATOR_DATETYPES as any;

// this is just a placeholder
export type RunConfigOption = any;

export type SimulatorConfig = {

    id?: number;
    sku?: string;
    displayName: string;
    description?: string;
    thumbnailUrl?: string;
    allowedAccessLevels: AccessLevel[];
    subscriptionLevel?: number;
    active: boolean;
    // inputs
    datasetId?: number;
    leverConfigs: SimulatorLeverConfig[];
    calculations: SimulatorCalculation[];
    // outputs
    inputGroups: SimulatorInputGroup[];
    staticValues: SimulatorInputConfig[];
    chartConfig: SimulatorChartConfig;
    tableConfigs: SimulatorTableConfig[];
}

export type SimulatorTableConfig = {
    title: string;
    columns: SimulatorTableColumnConfig[];
    data: string[][];
}

export type SimulatorTableColumnConfig = {
    title: string;
    datatype: SimulatorDatatype;
}

export type SimulatorLeverConfig = {
    code: string;
    label?: string;
    datatype?: SimulatorDatatype;
    formula?: string;
    excludeFromSavedSims?: boolean;
    persist?: boolean;
    resetCodes?: string[];
}

export type SimulatorCalculation = {
    code: string;
    datatype: SimulatorDatatype;
    formula: string;
}

export type SimulatorDataset = {
    id: number;
    sku: string; // this is the url param passed to the insight service (i.e. POST /api/insights/:key
    displayName: string;
    description?: string;
    valueConfigs: SimulatorDatasetValue[];
}

export type SimulatorDatasetValue = {
    code: string;
    label: string;
    datatype: SimulatorDatatype;
    sampleValue?: number;
}

export type SimulatorInputConfig = {

    label: string;
    inputCodes: string[];
    tip?: string;
    collapsable?: boolean;
}

export type SimulatorInputGroupLayout = 'label-input-label-input' | 'label-input' | 'label-input2x' | 'label-input3x';

export type SimulatorInputGroup = {
    title: string;
    layout?: SimulatorInputGroupLayout;
    tip?: string;
    headers?: string[],
    inputs: SimulatorInputConfig[];
    collapseMessage?: string;
}

// todo: figure out how to support arrays | string[] | number[]
export type SimulatorValues = Record<string, number | undefined>;

export type SimulatorChartConfig = {
    type: ByzChartType;
    values: SimulatorChartValueConfig[];
    layout?: ByzChartConfig;
}

export type SimulatorChartValueConfig = {
    label?: string;
    code: string;
    datatype?: SimulatorDatatype;
    color?: string;
}