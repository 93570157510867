import './Home.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {ByzzerButton} from "@byzzer/ui-components";
import {openImagePicker} from "@/components/ImagePicker";

export type HomeProps = {} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'home';

export function Home({className, ...props}: HomeProps) {

    async function openModal() {
        const url = await openImagePicker();
        console.log('closed', url)
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__content`}>
            <h1>Welcome To The Byzzer Sim Studio</h1>
            <Link to={'/qa/simulators'}>Get Started</Link>
        </div>
    </div>
}

export default Home;