import './LeverInput.scss';
import React, {ChangeEvent, useEffect, useLayoutEffect, useRef, useState, KeyboardEvent} from "react";
import classnames from "classnames";
import {inputToRawValue, isNumeric, rawValueToInput} from "@/utils";
import {SimulatorDataset, SimulatorDatatype} from "@/types/types";
import {ByzzerChangeEventHandler} from "@byzzer/ui-components";
import {ControlledInput} from "@/components/ControlledInput";

export type LeverInputProps = {
    type?: SimulatorDatatype;
    name?: string;
    value: number | undefined;
    onChange?: ByzzerChangeEventHandler<number | undefined>;
    readOnly?: boolean;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'lever-input';

export function LeverInput({
                               className,
                               type = 'decimal',
                               readOnly = false,
                               name,
                               value,
                               onChange,
                               placeholder,
                               ...props
                           }: LeverInputProps) {

    const [internalValue, setInternalValue] = useState<string>('');

    useEffect(() => {
        setInternalValue(rawValueToInput(type, value));
    }, [value])

    function handleChange(e: ChangeEvent<HTMLInputElement>): void {

        if (!isNumeric(e.target.value)) return;

        setInternalValue(e.target.value);
    }

    function handleKeyDown({target, key}: KeyboardEvent<HTMLInputElement>): void {

        if(key === 'Enter') {
            onChange?.({
                name,
                value: inputToRawValue(type, internalValue)
            });
            (target as HTMLInputElement).blur();
        }
    }

    function handleBlur(): void {
        onChange?.({
            name,
            value: inputToRawValue(type, internalValue)
        });
    }

    return <div className={classnames(baseClassName, className, `${baseClassName}--${type}`)} {...props}>
        <ControlledInput className={classnames(`${baseClassName}__input`)}
                         name={name}
                         placeholder={placeholder}
                         onKeyDown={handleKeyDown}
                         onBlur={handleBlur}
                         value={internalValue}
                         readOnly={readOnly}
                         onChange={handleChange}/>
    </div>
}


export default LeverInput;