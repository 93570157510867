import axios, {AxiosProgressEvent, AxiosRequestConfig} from "axios";
import {api} from "@/config";
import {
    SimulatorCalculation, SimulatorChartConfig,
    SimulatorConfig,
    SimulatorDataset, SimulatorInputConfig,
    SimulatorInputGroup,
    SimulatorLeverConfig, SimulatorTableConfig
} from "@/types/types";
import {getAuth} from "firebase/auth";
import {FileContent} from "use-file-picker/types";

const http = axios.create({
    baseURL: api.ablyApiBaseUrl,
});

http.interceptors.request.use(async (config) => {
    const currentUser = getAuth().currentUser;
    if (currentUser) {
        config.headers!.Authorization = `Bearer ${await currentUser.getIdToken()}`;
    }
    return config;
});

export type SimulatorSummary = {
    id: number;
    sku: string;
    displayName: string;
    description: string;
    thumbnailUrl: string;
    createdDtm: Date;
    updatedDtm: Date;
}

export async function getAllDatasets(env: string): Promise<SimulatorDataset[]> {

    return httpGet(env, '/datasets');
}

export async function getSimulators(env: string): Promise<SimulatorSummary[]> {

    return httpGet(env, '/simulators');
}

export async function saveSimulator(env, simulatorConfig: SimulatorConfig): Promise<SimulatorConfig> {

    const {
        id,
        leverConfigs,
        calculations,
        // outputs
        inputGroups,
        staticValues,
        chartConfig,
        tableConfigs,
        ...simulator
    } = simulatorConfig;
    const body = {
        id,
        ...simulator,
        config: {
            leverConfigs,
            calculations,
            // outputs
            inputGroups,
            staticValues,
            chartConfig,
            tableConfigs,
        }
    }
    let response: any;
    if (id !== undefined) {
        response = await httpPatch(env, `/simulators/${id}`, body);
    } else {
        response = await httpPost(env, '/simulators', body);
    }

    return toSimulatorConfig(response);
}


export async function getSimulator(env: string, id: number): Promise<SimulatorConfig> {

    const response = await httpGet(env, `/simulators/${id}`);
    return toSimulatorConfig(response);
}

function toSimulatorConfig(response: any): SimulatorConfig {
    const {config, ...simulator} = response;
    const {calculations, chartConfig, inputGroups, leverConfigs, staticValues, tableConfigs} = config;
    return {
        ...simulator,
        calculations,
        chartConfig,
        inputGroups,
        leverConfigs,
        staticValues,
        tableConfigs,
    }
}

type CDNImage = {
    name: string;
    url: string;
}

export async function getCDNImages(): Promise<CDNImage[]> {
    return httpGet('sandbox', `/cdn_files/images`);
}

type CdnUploadUrlResponse = {
    cdnUrl: string;
    uploadUrl: string;
}

type CdnUploadRequest = {
    file: File,
    path?: string;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export async function uploadCdnFile({file, path = '', onUploadProgress}: CdnUploadRequest): Promise<string> {
    const {cdnUrl, uploadUrl} = await httpPost<CdnUploadUrlResponse>('sandbox', `/cdn_files.generateSignedUrl`, {
        name: file.name.replace(/\s+/g, '_'),
        path: `images/simulators/${path}/`,
        contentType: file.type
    });
    await axios.put(uploadUrl, file, {
        headers: {
            'Content-Type': file.type
        },
        onUploadProgress
    });

    return cdnUrl;
}

export async function generateCdnUploadUrl(name: string, contentType: string, path: string = ''): Promise<CdnUploadUrlResponse> {
    return httpPost('sandbox', `/cdn_files.generateSignedUrl`, {
        name: name.replace(/\s+/g, '_'),
        path: `images/simulators/${path}`,
        contentType,
    });
}

export async function copyImageToCdn(url: string, path: string = ''): Promise<string> {
    const {cdnUrl} = await httpPost('sandbox', `/cdn_files.copyRemoteImage`, {
        url,
        path: 'images/simulators'
    });
    return cdnUrl;
}

export function useService() {

    return {}
}


/**
 * A wrapper around axios.httpGet that adds our custom error handling
 * @param env
 * @param url
 * @param config
 * @returns {Promise<any>}
 */
async function httpGet<T = any>(
    env: string,
    url: string,
    config?: AxiosRequestConfig
): Promise<T> {
    try {
        const {data} = await http.get(url, {
            ...config,
            headers: {
                "x-env": env,
            },
        });
        return data;
    } catch (err) {
        handleError(err);
    }
    throw "unreachable";
}

/**
 * A wrapper around axios.httpPost that adds our custom error handling
 * @param env
 * @param url
 * @param data
 * @param config
 * @returns {Promise<any>}
 */
async function httpPost<T = any>(
    env: string,
    url: string,
    data?: any,
    config?: AxiosRequestConfig
): Promise<T> {
    try {
        const {data: result} = await http.post(url, data, {
            ...config,
            headers: {
                "x-env": env,
            },
        });
        return result;
    } catch (err) {
        handleError(err);
    }
    throw "unreachable";
}

/**
 * A wrapper around axios.httpPatch that adds our custom error handling
 * @param env
 * @param url
 * @param data
 * @param config
 * @returns {Promise<any>}
 */
async function httpPatch<T = any>(
    env: string,
    url: string,
    data?: any,
    config?: AxiosRequestConfig
): Promise<T> {
    try {
        const {data: result} = await http.patch(url, data, {
            ...config,
            headers: {
                "x-env": env,
            },
        });
        return result;
    } catch (err) {
        handleError(err);
    }
    throw "unreachable";
}

/**
 * A wrapper around axios.httpPatch that adds our custom error handling
 * @param env
 * @param url
 * @param data
 * @param config
 * @returns {Promise<any>}
 */
async function httpPut<T = any>(
    env: string,
    url: string,
    data?: any,
    config?: AxiosRequestConfig
): Promise<T> {
    try {
        const {data: result} = await http.put(url, data, {
            ...config,
            headers: {
                "x-env": env,
            },
        });
        return result;
    } catch (err) {
        handleError(err);
    }
    throw "unreachable";
}

/**
 * A wrapper around axios.delete that adds our custom error handling
 * @param url
 * @param options
 * @returns {Promise<any>}
 */
async function httpDelete<T>(
    env: string,
    url: string,
    config?: AxiosRequestConfig
): Promise<T> {
    try {
        const {data: result} = await http.delete(url, {
            ...config,
            headers: {
                "x-env": env,
            },
        });
        return result;
    } catch (err) {
        handleError(err);
    }
    throw "unreachable";
}

/**
 *
 * common error handle that should be used by all functions
 * @param err
 */
function handleError(err): void {
    // todo: add common error handling and event triggering for connectivity issues
    if (err?.response) {
        const {status, data} = err.response;
        throw {
            status,
            code: data?.code,
            id: data?.id,
        };
    } else if (err.isAxiosError) {
        throw {
            status: -1,
            code: "network_error",
        };
    }
    throw err;
}