import './Catalog.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {getSimulators, SimulatorSummary} from "@/services/api.service";
import {Link, NavLink, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {ByzzerButton, ByzzerChangeEvent, ByzzerSelect, ByzzerSelectOption} from "@byzzer/ui-components";
import {getAuth} from "firebase/auth";
import {SimulatorImportButton} from "@/components/SimulatorImportButton";
import {SimulatorConfig} from "@/types/types";
import {cssUrlVar} from "@/utils/cssUrlVar";
import {ByzzerMask} from "@/components/ByzzerMask";
import {useUser} from "@/contexts";

export type CatalogProps = {} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'catalog';
const env = 'qa';

export function Catalog({className, ...props}: CatalogProps) {

    const navigate = useNavigate();
    const {envs} = useUser();
    const {env = 'qa'} = useParams<{ env: string }>();
    const [simulators, setSimulators] = useState<SimulatorSummary[]>([]);
    const [envOptions, setEnvOptions] = useState<ByzzerSelectOption[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadFailed, setLoadFailed] = useState<boolean>(false);

    useEffect(() => {
        ;(async () => {
            try {
                setLoadFailed(false);
                setSimulators(await getSimulators(env));
            } catch(err) {
                setLoadFailed(true);
            }
            finally {
                setLoading(false);
            }
        })();
    }, [env]);

    function edit(id: number): void {

        navigate(`/${env}/editor/${id}`);
    }

    function createNew(): void {
        navigate(`/${env}/editor`)
    }

    async function logout(): Promise<void> {
        if (confirm('Are you sure you want to logout?')) {
            await getAuth().signOut();
            navigate('/login');
        }
    }

    function handleImport(simulator: SimulatorConfig): void {
        // setSimulator(simulator);
    }

    function handleEnvChange(e: ByzzerChangeEvent<string>) {
        navigate(`/${e.value}/simulators`)
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <header className={`${baseClassName}__header`}>
            <h1 className={`${baseClassName}__title`}>
        <span className={`${baseClassName}__env`}>
            {env}
        </span> Simulators
            </h1>
            <ByzzerSelect className={`${baseClassName}__env-picker`}
                          options={envs}
                          value={env}
                          allowClear={false}
                          onChange={handleEnvChange as any}/>
        </header>
        <main className={`${baseClassName}__content`}>
            <ByzzerMask loading={loading}>Loading Simulators</ByzzerMask>
            {loadFailed && <div className={`${baseClassName}__error`}>Unable to load simulators for {env}</div>}
            <div className={`${baseClassName}__simulators`}>
                {simulators.map(simulator => (
                    <div className={`${baseClassName}__simulator`}
                         style={cssUrlVar('thumbnail', simulator.thumbnailUrl)}
                         onClick={() => edit(simulator.id)}
                         key={simulator.id}>
                        <div className={`${baseClassName}__title`}>
                            {simulator.displayName}
                        </div>
                        <div className={`${baseClassName}__description`}>
                            {simulator.description}
                        </div>
                    </div>
                ))}
            </div>
        </main>
        <footer className={`${baseClassName}__actions`}>
            <ByzzerButton className={`${baseClassName}__logout`} onClick={logout}>Logout</ByzzerButton>
            <SimulatorImportButton onImport={handleImport}>Import</SimulatorImportButton>
            <ByzzerButton onClick={createNew}>Create New Simulator</ByzzerButton>

        </footer>
    </div>
}

export default Catalog;