import './SimulatorChart.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {
    SimulatorChartConfig,
    SimulatorChartValueConfig,
    SimulatorDataset, SimulatorDatatype,
    SimulatorInputConfig,
    SimulatorValues
} from "@/types/types";
import {ByzChart, ByzChartConfig, ByzChartData, ByzChartType} from "@/components/visualizations/ByzChart";
import {getNextColor, rawValueToDisplay, rawValueToInput} from "@/utils";
import {merge} from 'lodash';

export type SimulatorChartProps = {
    config?: SimulatorChartConfig;
    values: Record<string, number | undefined>;
    datatypeMap: Record<string, SimulatorDatatype>;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator-chart';

export function SimulatorChart(
    {
        className,
        datatypeMap,
        config,
        values,
        ...props
    }: SimulatorChartProps) {

    const [chartData, setChartData] = useState<ByzChartData>();
    const [type, setType] = useState<ByzChartType | undefined>(config?.type);
    const [layout, setLayout] = useState<ByzChartConfig | undefined>(config?.layout);

    useEffect(() => {
        setChartData(toChartData(config?.values, values))
    }, [values]);

    useEffect(() => {
        setType(config?.type);
        setLayout(merge({
            plot: {
                barWidth: 'auto',
                valueBox: {
                    rules: [
                        {rule: '%v < 0', placement: 'bottom', text: '%data-display-value'},
                        {rule: '%v >= 0', placement: 'top', text: '%data-display-value'}
                    ]
                }
            },
            scaleY: {
                visible: false,
                format: '%v%'
            }
        }, config?.layout))
    }, [config])

    return <div className={classnames(baseClassName, className)} {...props}>
        <ByzChart className={`${baseClassName}__chart`} type={type} config={layout} data={chartData}/>
    </div>

    function toChartData(configs: SimulatorChartValueConfig[] | undefined, values: Record<string, number | undefined>): ByzChartData | undefined {

        return {
            series: [{
                values: configs?.map(v => Number(values[v.code])) ?? [],
                'data-display-value': configs?.map(v => rawValueToDisplay(datatypeMap[v.code], values[v.code])) ?? [],
                styles: configs?.map((v, i) => ({
                    backgroundColor: v.color || getNextColor(i)
                })),
            }],
            scaleX: {
                visible: true,
                labels: configs?.map(v => v.label)
            }
        };
    }

}


export default SimulatorChart;