import '../InlineInputConfigEditor.scss';
import React, {ChangeEvent} from "react";
import {ByzzerButton, ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerSelect} from "@byzzer/ui-components";
import {SimulatorInputConfig, SimulatorInputGroupLayout} from "@/types/types";
import {useSimulator} from "@/pages/Simulator";

export type InputRowEditorProps = {
    name?: string;
    value: SimulatorInputConfig;
    onChange: ByzzerChangeEventHandler<SimulatorInputConfig>;
    columnCount?: number;
    layout?: SimulatorInputGroupLayout;
    onRemove?(name: string): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const columnCountsByLayout: Record<SimulatorInputGroupLayout, number> = {
    "label-input": 1,
    "label-input-label-input": 1,
    "label-input2x": 2,
    "label-input3x": 3,
}
const baseClassName = 'inline-input-config-editor';

export function InputRowEditor({
                                   className,
                                   name,
                                   value,
                                   layout = 'label-input-label-input',
                                   onChange,
                                   onRemove,
                                   ...props
                               }: InputRowEditorProps) {

    const {variableOptions} = useSimulator();
    const columnCount = columnCountsByLayout[layout]

    function handleLabelChange({target}: ChangeEvent<HTMLInputElement>): void {
        onChange?.({
            name,
            value: {
                ...value,
                label: target.value
            }
        })
    }

    function handleSourceChange(e: ByzzerChangeEvent<string>) {
        const index = Number(e.name);
        const leverCodes = value.inputCodes;
        leverCodes[index] = e.value;
        onChange?.({
            name,
            value: {
                ...value,
                inputCodes: [...leverCodes]
            }
        })
    }

    return <>

        <input className={`${baseClassName}__label`}
               type={'text'}
               value={value.label}
               name={'label'}
               onChange={handleLabelChange}/>
        {Array.from({length: columnCount}).map((_, index) => (
            <ByzzerSelect key={index}
                          name={String(index)}
                          className={`${baseClassName}__lever-selector`}
                          value={value.inputCodes[index]}
                          onChange={handleSourceChange as any}
                          allowClear={false}
                          options={variableOptions}/>

        ))}
        <ByzzerButton onClick={() => onRemove?.(name!)}
                      type={'remove'}
                      iconType={'delete'}
                      tipDelay={[250, 0]}
                      tipLocation={'bottom'}
                      tip={'Remove'}/>
    </>
}

export default InputRowEditor;