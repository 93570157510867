import './App.scss'
import {useRoutes} from "react-router-dom";
import {routes} from "./routes";
import {useInitialized} from "@/contexts";
import {useEffect} from "react";
import {hasNewReleaseNotes, showWhatsNewModal} from "@/components/WhatsNew/WhatsNew";

export default function App() {

    const route = useRoutes(routes);
    const initialized = useInitialized();
    useEffect(() => {
        if(initialized && hasNewReleaseNotes()) {
            showWhatsNewModal();
        }
    }, [initialized])

    if(!initialized) return <></>



    return (<>
        {route}
    </>)
}
