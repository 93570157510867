import './SimulatorValueTestPanel.scss';
import React, {ChangeEvent, useEffect, useState} from "react";
import classnames from "classnames";
import {SimulatorConfig, SimulatorDatatype, SimulatorLeverConfig, SimulatorValues} from "@/types/types";
import {ByzzerChangeEvent, ByzzerChangeEventHandler} from "@byzzer/ui-components";
import {inputToRawValue} from "@/utils";
import {SimulatorPanel} from "@/components/SimulatorPanel";
import {ValueManager} from "@/utils/ValueManager";
import {useSimulator} from "@/pages/Simulator";
import {LeverInput} from "@/components/LeverInput";

export type SimulatorValueTestPanelProps = {
    name?: string;
    simulator: SimulatorConfig;
    excludeLevers?: boolean;
    excludeDataset?: boolean;
    values?: Record<string, number | undefined>;
    onApply?: ByzzerChangeEventHandler<SimulatorValues>;
    onChange?: ByzzerChangeEventHandler<Record<string, number | undefined>>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'simulator-value-test-panel';

export function SimulatorValueTestPanel({
                                            className,
                                            name,
                                            simulator,
                                            onApply,
                                            values,
                                            onChange,
                                            excludeDataset = false,
                                            excludeLevers = false,
                                            ...props
                                        }: SimulatorValueTestPanelProps) {

    // const {evaluateFor} = useFormulas();
    const [valueManager] = useState<ValueManager>(new ValueManager());
    const [internalValues, setInternalValues] = useState<Record<string, number | undefined>>({});
    const {leverConfigs = []} = simulator;
    const {dataset} = useSimulator();

    useEffect(() => {

        const {leverConfigs = [], calculations = []} = simulator;
        valueManager.setVariableResetMap(leverConfigs);
        valueManager.setCalculations([...leverConfigs, ...calculations]
            .filter(v => v.formula)
            .reduce((result, {code, formula}) => ({
                ...result,
                [code]: formula
            }), {})
        );
        setInternalValues(valueManager.getRawValues());
    }, [simulator.leverConfigs, simulator.calculations]);

    useEffect(() => {

        if(!dataset) return;

        const staticValues = dataset.valueConfigs.reduce((result, {code, sampleValue}) => ({
            ...result,
            [code]: sampleValue
        }), {});

        valueManager.setStaticValues(staticValues);

        setInternalValues(valueManager.getRawValues());
    }, [dataset]);

    useEffect(() => {
        const {calculations, leverConfigs} = simulator;

        valueManager.setDatatypes([...(dataset?.valueConfigs ?? []), ...(calculations ?? []), ...(leverConfigs ?? [])]
            .reduce((result, {code, datatype}) => ({
                [code]: datatype
            }), {})
        )
        setInternalValues(valueManager.getRawValues());
    }, [dataset, simulator.calculations, simulator.leverConfigs])


    function handleStaticValueChange(e: ByzzerChangeEvent<number | undefined>) {

        // todo: update the static values instead of the levers when a static value changes
        handleLeverChange(e);
    }

    function handleLeverChange(e: ByzzerChangeEvent<number | undefined>) {

        valueManager.updateLever(e.name!, e.value);

        setInternalValues(valueManager.getRawValues());
        onChange?.({
            name,
            value: valueManager.getRawValues()
        })
    }

    function handleApplyClick() {

        onApply?.({
            name,
            value: valueManager.getRawValues()
        })
    }

    return (
        <SimulatorPanel className={classnames(baseClassName, className)}
                        name={'leverTester'}
                        title={'Test Your Inputs'}
                        tip={<>
                            <p>Use this as a playground to test your formulas.</p>
                            <p>Watch how they interact with each other and your calculations.</p>
                            <p>Notice how only values that are related through their formulas are updated when you make
                                a change.</p>
                        </>}
                        emptyContent={<>
                            <p>You haven't created any Levers.</p>
                            <p>You can create one now using the "Create Your Levers" panel.</p>
                        </>}
                        actions={[
                            {include: Boolean(onApply), onClick: handleApplyClick, label: 'Apply'}
                        ]}
                        {...props}>
            {!excludeLevers && <>
                <h2>Levers</h2>
                <div className={`${baseClassName}__input-group`}>
                    {leverConfigs.filter(v => v.code).sort(compareConfigs).map(config => (
                        <Simpute config={config} key={config.code}
                                 value={internalValues[config.code]}
                                 onChange={handleLeverChange}/>
                    ))}
                </div>
            </>}
            {!excludeDataset && <>
                <h2>Dataset Values</h2>
                <div className={`${baseClassName}__input-group`}>
                    {dataset?.valueConfigs.filter(v => v.code).sort(compareConfigs).map(config => (
                        <Simpute config={config} key={config.code}
                                 value={internalValues[config.code]}
                                 onChange={handleStaticValueChange}/>
                    ))}
                </div>
            </>}
        </SimulatorPanel>
    );
}

type SimputProps = {
    config: SimulatorLeverConfig;
    onChange: ByzzerChangeEventHandler<number | undefined>;
    value: number | undefined;
}

function Simpute({config, onChange, value}: SimputProps) {
    const {code, label, datatype, formula} = config;

    return <div className={`${baseClassName}__lever`}>
        <div className={`${baseClassName}__label`}>{label}</div>
        <div className={`${baseClassName}__code`}>${code}</div>
        <LeverInput className={`${baseClassName}__input`}
                    name={code}
                    type={datatype}
                    value={value}
                    placeholder={formula}
                    onChange={onChange}/>
        <div className={`${baseClassName}__value-debugger`}>
            actual: {value}
        </div>
    </div>
}

function compareConfigs(a: SimulatorLeverConfig, b: SimulatorLeverConfig): number {
    return a.code.localeCompare(b.code);
}

export default SimulatorValueTestPanel;