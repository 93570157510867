// @ts-ignore
import * as fjs from '@formulajs/formulajs';
import {tokenize} from "excel-formula-tokenizer";
import {VALID_FUNCTIONS} from "@/services/formulaFunctions";
import {extractFormulaVars} from "@/utils";
import {intersection} from 'lodash';

export class InvalidFormulaError extends Error {
    constructor(public invalidFunctions: string[], public invalidVariables: string[]) {
        super('invalid_functions');
    }
}

export class MaxParseLoopsError extends Error {
    constructor() {
        super('max_loops');
    }
}

export class CircularReference extends Error {
}

export type ValidationErrors = {
    badSyntax: boolean;
    invalidVariables: string[];
    invalidFunctions: string[];
    missingPrefixes: string[];
    badCharacters: string[];
};

const invalidVarNameRegex = /^[A-Za-z_][\w_]*$/;
const GLOBAL_VARIABLES = ['null', 'undefined']; // todo: extend with other acceptable values

export function validateFormula(formula: string, variables: string[]): true | ValidationErrors {

    if (!formula?.trim()) return true;
    variables = variables.map(v => `$${v}`);
    const invalidVariables: string[] = [];
    const invalidFunctions: string[] = [];
    const missingPrefixes: string[] = [];
    const badCharacters: string[] = formula.match(/([\^])/g) ?? []
    let startStopBalance = 0;

    let tokens;

    try {
        tokens = tokenize(formula);
    } catch (err) {
        console.error(err)
        return {
            badSyntax: true,
            invalidFunctions,
            invalidVariables,
            missingPrefixes,
            badCharacters
        }
    }


    tokens.forEach(({value, type, subtype}) => {

        if (type === 'operand') {
            if (value.startsWith('$') && ![...variables, ...GLOBAL_VARIABLES].includes(value)) {
                invalidVariables.push(value);
            }

            if (invalidVarNameRegex.test(value)) {
                missingPrefixes.push(value);
            }
        }

        if (type === 'function' && subtype === 'start' && !VALID_FUNCTIONS.includes(value.toUpperCase())) {
            invalidFunctions.push(value.toUpperCase());
        }

        if (subtype === 'start') {
            startStopBalance++;
        } else if (subtype === 'stop') {
            startStopBalance--;
        }
    })

    if (startStopBalance !== 0 || invalidVariables.length || invalidFunctions.length || missingPrefixes.length || badCharacters.length) {
        return {
            badSyntax: Boolean(startStopBalance),
            invalidFunctions,
            invalidVariables,
            missingPrefixes,
            badCharacters
        }
    }

    return true;
}

export function evaluateFormula2(formula: string, derivedValues: Record<string, number>, explicitValues: Record<string, number>): number | undefined {

    const finalFormula = tokenize(formula).map(({value, type, subtype}) => {
        if (subtype === 'start' && type === 'function') {
            return `f.${value.toUpperCase()}(`;
        }

        if (subtype === 'start' && type === 'subexpression') {
            return '(';
        }

        if (subtype === 'stop' && type === 'subexpression') {
            return ')'
        }

        if (subtype === 'stop' && type === 'function') {
            return ')';
        }
        return value;
    }).join('');

    return;
}

// todo: add support for identifying formulas in the data values and recursively parse them,
//  otherwise there are lots of edge cases that won't work
export function evaluateFormula<TResult extends string | number = number>(
    formula: string | undefined,
    values: Record<string, string | number | undefined>,
    explicitValues: Record<string, number | undefined> = {},
    id?: string): TResult | undefined {

    try {
        const MAX_LOOPS = 10;
        if (!formula) return;

        const f = {
            ...fjs,
            ISNULL(value: any, ifNull: any, ifNotNull: any): any {
                return value === null ? ifNull : ifNotNull;
            },
            ISBLANK(value: any, ifBlank: any, ifNotBlank: any): any {
                return value === null || value === undefined || value === '' ? ifBlank : ifNotBlank;
            }
        }
        let transformedFormula = formula;
        let loopCnt = 0;
        let seen: string[] = [];
        let tmpSeen: string[] = [];
        // keep applying replacing all $ prefixed values until there are none left
        // this is necessary because variables can contain reference to formulas that reference other formulas
        while (/\$\w/.test(transformedFormula) && loopCnt < MAX_LOOPS) {

            tmpSeen = [];
            transformedFormula = transformedFormula.replace(/\$\$\w+/g, (key: string): string => {
                return String(explicitValues[key.replace('$$', '')] ?? null);
            }).replace(/\$!?(\w+)/g, (_: string, key: string) => {
                const value = values[key];
                // const formulaVars = extractFormulaVars(String(value));
                // if(typeof value === 'string') {
                //     tmpSeen.push(key);
                //     if(intersection(formulaVars, seen).length) {
                //         console.error('circular reference', {
                //             id,
                //             formula,
                //             formulaVars,
                //             seen
                //         });
                //         return '';
                //     }
                //     // throw new CircularReference();
                // }
                if (value !== undefined) {
                    return JSON.stringify(value).replace(/^"/, '').replace(/"$/, '');
                }
                return '';
            });
            seen = seen.concat(tmpSeen)
            loopCnt++;
        }

        // console.log({id, transformedFormula})

        if (loopCnt >= MAX_LOOPS) {
            console.error('max formula parse loops reached', {
                formula
            });
            return;
        }

        // console.log(transformedFormula)

        const finalFormula = tokenize(transformedFormula).map(({value, type, subtype}) => {
            if (subtype === 'start' && type === 'function') {
                return `f.${value.toUpperCase()}(`;
            }

            if (subtype === 'start' && type === 'subexpression') {
                return '(';
            }

            if (subtype === 'stop' && type === 'subexpression') {
                return ')'
            }

            if (subtype === 'stop' && type === 'function') {
                return ')';
            }
            return value;
        }).join('');


        // if(formula.includes('$$')) {
        //     console.log({formula, transformedFormula, finalFormula, explicitValues})
        // }


        // CAUTION: eval is dangerous especially when it can be execute on arbitrary code.
        // todo: add a sanitizer and/or validator to ensure this only runs a trusted excel style formula
        const result = eval(finalFormula);

        // console.log({result})

        // it's possible for eval to return a function or object which we don't want, this protects against that
        if (!['string', 'number'].includes(typeof result)) return;

        return result as TResult;
    } catch(err) {
        return;
    }

}