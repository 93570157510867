import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './sass/index.scss';
import {BrowserRouter} from "react-router-dom";
// @ts-ignore
import zingchart from 'zingchart/es6';
// EXPLICITLY IMPORT MODULE
import 'zingchart/modules-es6/zingchart-depth.min.js';
import 'zingchart/modules-es6/zingchart-waterfall.min.js';
import {firebaseConfig, zingChartConfig} from "@/config";
import {UserProvider} from "@/contexts";
import ModalProvider from "@/contexts/ModalContext";
import {initializeApp} from 'firebase/app';

initializeApp(firebaseConfig);

zingchart.LICENSE = [zingChartConfig.appLicense];

ReactDOM.createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
        <ModalProvider>
            <UserProvider>
                <App/>
            </UserProvider>
        </ModalProvider>
    </BrowserRouter>
);
