import './SimulatorTableEditor.scss';
import React, {ChangeEvent, useImperativeHandle} from "react";
import classnames from "classnames";
import {SimulatorTableConfig} from "@/types/types";
import {ByzzerButton, ByzzerChangeEvent, ByzzerChangeEventHandler, ByzzerSelect} from '@byzzer/ui-components';
import {useSimulator} from "@/pages/Simulator";

export type SimulatorTableEditorRef = {
    addRow(): void;
    addColumn(): void;
}

export type SimulatorTableEditorProps = {
    name?: string;
    value: SimulatorTableConfig;
    onApply?: ByzzerChangeEventHandler<SimulatorTableConfig>;
    onChange?: ByzzerChangeEventHandler<SimulatorTableConfig>;
    byzRef?: React.Ref<SimulatorTableEditorRef>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'simulator-table-editor';

export function SimulatorTableEditor({
                                         className,
                                         value,
                                         name,
                                         onChange,
                                         onApply,
                                         byzRef,
                                         ...props
                                     }: SimulatorTableEditorProps) {

    const {variableOptions} = useSimulator();
    const values = value.data;
    const canRemoveRow = values.length > 1;
    const canRemoveCol = values[0].length > 2;

    useImperativeHandle(byzRef, () => ({
        addRow,
        addColumn
    }), [value]);

    function addRow() {
        const {data} = value;
        onChange?.({
            name,
            value: {
                ...value,
                data: [...data, data[0].map(_ => '')]
            }
        })
        // setValues(values => [...values, values[0].map(_ => '')]);
    }

    function removeRow(index: number): void {

        if (!confirm('Are you sure you want to remove this row?')) return;

        const {data} = value;
        onChange?.({
            name,
            value: {
                ...value,
                data: data.filter((_, i) => i !== index)
            }
        })
        // setValues(values => values.filter((_, i) => i !== index));
    }

    function addColumn() {
        const {data, columns} = value;
        onChange?.({
            name,
            value: {
                ...value,
                columns: [...columns, {title: '', datatype: 'decimal'}],
                data: data.map(row => [...row, ''])
            }
        })
        // setValues(values => values.map(row => [...row, '']));
    }

    function removeColumn(index: number): void {

        if (!confirm('Are you sure you want to remove this column?')) return;

        const {data, columns} = value;
        onChange?.({
            name,
            value: {
                ...value,
                columns: columns.filter((_, i) => i !== index),
                data: data.map(row => row.filter((_, i) => i !== index))
            }
        });
        // setValues(values => values.map(row => row.filter((_, i) => i !== index)));
    }

    function handleRowTitleChange({target}: ChangeEvent<HTMLInputElement>) {
        const rowIndex = Number(target.name);
        const {data} = value;
        onChange?.({
            name,
            value: {
                ...value,
                data: data.map((row, r) => row.map((cell, c) => rowIndex === r && c === 0 ? target.value : cell))
            }
        })
        // setValues(values => values.map((row, r) => row.map((cell, c) => rowIndex === r && c === 0 ? target.value : cell)));
    }

    function handleCodeChange(e: ByzzerChangeEvent<string>): void {
        let [r, c] = e.name!.split(':');
        const rowIndex = Number(r);
        const colIndex = Number(c);
        const {data} = value;
        onChange?.({
            name,
            value: {
                ...value,
                data: data.map((row, r) => row.map((cell, c) => rowIndex === r && colIndex === c ? e.value : cell))
            }
        });
        // setValues(values => values.map((row, r) => row.map((cell, c) => rowIndex === r && colIndex === c ? e.value : cell)));
    }

    function handleHeaderChange({target}: ChangeEvent<HTMLInputElement>): void {
        const index = Number(target.name);
        const title = target.value;
        onChange?.({
            name,
            value: {
                ...value,
                columns: value.columns.map((c, i) => i === index ? {...c, title} : c)
            }
        })
    }

    function handleTitleChange({target}: ChangeEvent<HTMLInputElement>): void {

        onChange?.({
            name,
            value: {
                ...value,
                title: target.value
            }
        })
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <input className={`${baseClassName}__title`}
               value={value.title}
               onChange={handleTitleChange}
               placeholder={'Table Title'}/>
        <table className={`${baseClassName}__values`}>
            <thead>
            <tr>
                {value.columns.map((column, c) => (
                    <th key={c}>
                        <input
                            placeholder={'Column Title'}
                            name={String(c)}
                            value={column.title}
                            onChange={handleHeaderChange}/>
                    </th>
                ))}
                {canRemoveRow && <th className={`${baseClassName}__remove-cell`}/>}
            </tr>
            </thead>
            <tbody>
            {values.map((row, r) => (<tr key={r}>
                <td className={`${baseClassName}__row-title`}>
                    <input className={`${baseClassName}__row-title-input`}
                           name={`${r}`}
                           placeholder={'Row Title'}
                           value={row[0]}
                           onChange={handleRowTitleChange}/>
                </td>
                {/* the first cell contains the title so skip it*/}
                {row.slice(1).map((value, c) => (<td key={c}>
                    <ByzzerSelect name={`${r}:${c + 1}`}
                                  options={variableOptions}
                                  value={value}
                                  onChange={handleCodeChange as any}
                                  allowClear={false}/>
                </td>))}
                {canRemoveRow && (
                    <td className={`${baseClassName}__remove-cell`}>
                        <ByzzerButton className={`${baseClassName}__remove-row-btn`}
                                      onClick={() => removeRow(r)}
                                      type={'remove'} iconType={'delete'}
                                      tipDelay={[250, 0]}
                                      tipLocation={'right'}
                                      tip={'Remove Row'}/>
                    </td>
                )}
            </tr>))}
            {canRemoveCol && (<tr>
                <td className={`${baseClassName}__row-title`}/>
                {/* the first cell contains the title so skip it*/}
                {values[0].slice(1).map((_, c) => (
                    <td key={c}>
                        <ByzzerButton className={`${baseClassName}__remove-col-btn`}
                                      onClick={() => removeColumn(c + 1)}
                                      type={'remove'}
                                      iconType={'delete'}
                                      tipDelay={[250, 0]}
                                      tipLocation={'bottom'}
                                      tip={'Remove Column'}/>
                    </td>
                ))}
                {canRemoveRow && <td className={`${baseClassName}__remove-cell`}/>}
            </tr>)}
            </tbody>
        </table>
    </div>
}

export default SimulatorTableEditor;