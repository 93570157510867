import './Login.scss';
import {useEffect, useRef, useState} from "react";
import {useNavigate} from 'react-router-dom';
import {useUser} from "@/contexts/UserContext";
import {alert} from "@/components/Modal/Modal";
import {ByzzerButton} from "@byzzer/ui-components";
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';

export default function Login() {

    const navigate = useNavigate();
    const {user} = useUser();
    const formRef = useRef<HTMLDivElement>(null);
    const [busy, setBusy] = useState(false);

    useEffect(() => {
        if (user) {
            navigate('/dashboard', {replace: true})
        }
    }, [user]);

    const transitionClasses = {
        enter: "animated",
        enterActive: "fadeIn",
        exit: "animated",
        exitActive: "fadeOut",
        appear: 'animated',
        appearActive: 'bounceInUp'
    };

    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });

    function onCredentialChange({target}) {
        setCredentials(current => ({
            ...current,
            [target.name]: target.value
        }))
    }

    async function login() {

        try {
            setBusy(true)
            const {user} = await signInWithEmailAndPassword(getAuth(), credentials.email, credentials.password);
            navigate(`/qa/simulators`);
        } catch (err: any) {
            await alert({
                title: 'Login Failed',
                content: <>{err.message}</>
            })
            setBusy(false);
        }
    }

    async function onKeyDown({key}) {
        if(key === 'Enter') {
            return login()
        }
    }

    return <div className={'alby-login'}>

        <div className={'alby-login-form'} ref={formRef}>
                <header className={'alby-login-form__header'}>
                    <h1 className={'alby-login-form__title'}>Welcome To Sim Studio</h1>
                    <p>Use your Alby credentials to login.</p>
                </header>
                <main className={'alby-login-form__content'}>
                    <input name={'email'} onChange={onCredentialChange} value={credentials.email}
                           className={'alby-login__email'} placeholder={'Email'}/>
                    <input name={'password'} onChange={onCredentialChange}
                           onKeyDown={onKeyDown}
                           value={credentials.password}
                           className={'alby-login__email'} type={'password'} placeholder={'Password'}/>
                </main>
                <footer className={'alby-login-form__footer'}>
                    <ByzzerButton onClick={login}
                                  disabled={!credentials.password || !credentials.email}
                                  disabledTip={'You must enter an email and password to login.'}>
                        Login
                    </ByzzerButton>
                </footer>
            </div>
    </div>
}
