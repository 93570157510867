import './SimulatorStaticValues.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {SimulatorConfig, SimulatorDatatype, SimulatorInputConfig, SimulatorValues} from "@/types/types";
import {FormattedValue} from "@/components/SimulatorPreview/FormattedValue";

export type SimulatorStaticInputValuesProps = {
    simulator: SimulatorConfig;
    values: Record<string, number | undefined>;
    datatypeMap: Record<string, SimulatorDatatype | undefined>
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator-static-values';

export function SimulatorStaticValues({
                                          className,
                                          simulator,
                                          datatypeMap,
                                          values,
                                          ...props
                                      }: SimulatorStaticInputValuesProps) {

    return <div className={classnames(baseClassName, className)} {...props}>
        <header>
            <h1 className={'simulator-preview__title'}>Helpful Values</h1>
        </header>
        <main className={`${baseClassName}__values`}>
            {simulator?.staticValues?.map(({label, inputCodes}, i) => {
                const code = inputCodes[0];
                const type = datatypeMap[code];
                const value = values[code];
                return (
                    <div className={`${baseClassName}__value`} key={i}>
                        <div>{label}</div>
                        <FormattedValue type={type} value={value}/>
                    </div>
                );
            })}
        </main>
    </div>
}

export default SimulatorStaticValues;