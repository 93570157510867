import React, {ReactNode} from "react";

type SemVer = `${number}.${number}.${number}` | `${number}.${number}.${number}-${string}`;
type VerionReleaseNotes = {
    title?: string;
    messages: ReactNode[]
}

export const releaseNotes: Record<SemVer, VerionReleaseNotes> = {
    '1.0.1': {
        messages: [
            <>Added a new "currency" datatype to support rendering dollar values.</>,
            <>Currency and percent values are now displayed formatted in the tables.</>,
            <>Levers will only trigger an update when "enter" is pressed or they lose focus.</>,
            <>Checkout the "Promo Simulator (Development Version)" to see these features in action.</>
        ]
    },
    '1.0.2': {
        messages: [
            <>Formulas can use the "$$" prefix before a variable to reference the overridden (not the calculated) value
                of a lever.</>
        ]
    },
    '1.0.3': {
        messages: [
            <>Added support for exporting a properly formatted Excel spreadsheet. This feature has more coming.</>
        ]
    },
    '1.0.4': {
        messages: [
            <>Added support for two new formula functions; ISBLANK and ISNULL. When used with the $$ prefix these
                functions should simplify configuring levers that have circular dependencies.</>
        ]
    },
    '1.0.5': {
        messages: [
            <>Fixed the bug that prevented new Calculations from being accessible until the Simulator was saved and
                reloaded.</>,
            <>Circular references in formulas shouldn't cause the browser to run out of memory anymore.</>
        ]
    },
    '1.0.7': {
        messages: [
            <>
                <p>Added support for ignoring changes to a referenced variable in a lever's default formula by prefixing it with "$!".</p>
                <p>This allows users to edit multiple dependant values without overwriting their changes.</p>
            </>
        ]
    },
    '1.0.8': {
        messages: [
            <>Added a "Maintain Calculate Value" option to keep levers from recalculating until a $$ variable in their formula changes.</>
        ]
    },
    '1.0.10': {
        messages: [
            <>Added support for editing the simulator product data (i.e. description, thumbnail, access level, etc.).</>
        ]
    },
    '1.0.11': {
        messages: [
            <>Thumbnails can be selected directly from thd CDN.</>
        ]
    }
}