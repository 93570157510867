import './ImagePickerUpload.scss';
import React, {CSSProperties, useEffect, useState} from "react";
import classnames from "classnames";
import {useFilePicker} from "use-file-picker";
import {ByzzerButton, ByzzerChangeEventHandler} from "@byzzer/ui-components";
import {generateCdnUploadUrl, uploadCdnFile} from "@/services/api.service";
import {cssUrlVar} from "@/utils/cssUrlVar";

export type ImagePickerUploadProps = {
    onApply: ByzzerChangeEventHandler<string>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;
const baseClassName = 'image-picker-upload';

export function ImagePickerUpload({className, onApply, ...props}: ImagePickerUploadProps) {

    const [invalid, setInvalid] = useState<boolean>(true);
    const [content, setContent] = useState<string>('');
    const {plainFiles, filesContent, openFilePicker} = useFilePicker({
        accept: 'image/*',
        multiple: false,
        readAs: "DataURL",
        readFilesContent: true,
    });

    useEffect(() => {
        if (filesContent.length) {
            setContent(filesContent[0].content);
        } else {
            setContent('');
        }
    }, [filesContent]);

    async function handleApply() {

        const [file] = plainFiles;
        const url = await uploadCdnFile({
            file,
            path: 'thumbnails'
        });
        onApply({
            value: url
        })
    }

    function handleError() {
        setInvalid(true);
    }

    function handleLoad() {
        setInvalid(false);
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <div className={`${baseClassName}__preview`}>
            <img className={classnames(`${baseClassName}__image`, {
                [`${baseClassName}__image--invalid`]: invalid,
            })} src={content} onError={handleError} onLoad={handleLoad}/>
            {!plainFiles.length && <div className={`${baseClassName}__empty-state`}>
                No Image Selected
            </div>}
        </div>
        <footer className={`${baseClassName}__actions`}>
            <ByzzerButton className={`${baseClassName}__choose`} onClick={openFilePicker}>Choose A File</ByzzerButton>
            <ByzzerButton className={`${baseClassName}__apply`} onClick={handleApply}
                          disabled={invalid}>Apply</ByzzerButton>
        </footer>
    </div>
}

export default ImagePickerUpload;