import './SimulatorDataSetup.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {ByzzerChangeEvent} from "@byzzer/ui-components";
import {
    SimulatorCalculation,
    SimulatorDataset,
    SimulatorDatatype,
    SimulatorLeverConfig,
    SimulatorValues
} from "@/types/types";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css';
import SimulatorValueTestPanel from "@/components/SimulatorValueTestPanel/SimulatorValueTestPanel";
import SimulatorCalculationsEditorPanel from "@/components/SimulatorDataSetup/panels/SimulatorCalculationsEditorPanel";
import SimulatorSummaryPanel from "@/components/SimulatorDataSetup/panels/SimulatorSummaryPanel";
import SimulatorLeverEditorPanel from "@/components/SimulatorDataSetup/panels/SimulatorLeverEditorPanel";
import {useSimulator} from "@/pages/Simulator";
import SimulatorDatasetPicker from "@/components/SimulatorDataSetup/panels/SimulatorDatasetPicker";
import {SimulatorPanelContainer} from "@/components/SimulatorPanelContainer"; // Optional theme CSS

export type SimulatorCreatorProps = React.HTMLAttributes<HTMLDivElement> & {}

const baseClassName = 'simulator-data-setup';


export function SimulatorDataSetup({className, ...props}: SimulatorCreatorProps) {

    const [allValues, setAllValues] = useState<SimulatorValues>({});
    const {simulator, setSimulator, dataset, setDataset} = useSimulator();
    const [datatypeMap, setDatatypeMap] = useState<Record<string, SimulatorDatatype>>({})

    useEffect(() => {
        if (!simulator || !dataset) return;

        const {leverConfigs, calculations} = simulator;
        const {valueConfigs} = dataset;

        setDatatypeMap([...leverConfigs, ...calculations, ...valueConfigs].reduce((result, {code, datatype}) => ({
            ...result,
            [code]: datatype ?? 'decimal'
        }), {}));
    }, [simulator.leverConfigs, simulator.calculations, dataset]);

    function handleFormulasChange(e: ByzzerChangeEvent<SimulatorCalculation[]>): void {

        setSimulator(simulator => ({
            ...simulator,
            calculations: e.value
        }));
    }

    function handleLeverConfigChange(e: ByzzerChangeEvent<SimulatorLeverConfig[]>): void {
        setSimulator(simulator => ({
            ...simulator,
            leverConfigs: e.value
        }));
    }

    function handleApplyValues(e: ByzzerChangeEvent<SimulatorValues>): void {
        setAllValues(e.value);
    }

    function handleDatasetChange(e: ByzzerChangeEvent<SimulatorDataset>): void {

        setSimulator(simulator => ({
            ...simulator,
            datasetId: e.value.id
        }));
        setDataset(e.value)
    }

    return <SimulatorPanelContainer className={classnames(baseClassName, className)} {...props}>

        <SimulatorDatasetPicker
            className={classnames(`${baseClassName}__dataset-editor`)}
            value={simulator.datasetId}
            onChange={handleDatasetChange}/>

        <SimulatorLeverEditorPanel
            className={classnames(`${baseClassName}__lever-editors`)}
            simulator={simulator}
            onChange={handleLeverConfigChange}/>

        <SimulatorCalculationsEditorPanel
            className={classnames(`${baseClassName}__formulas`)}
            simulator={simulator}
            values={allValues}
            onChange={handleFormulasChange}/>

        <SimulatorValueTestPanel
            className={classnames(`${baseClassName}__levers`)}
            simulator={simulator}
            onApply={handleApplyValues}/>

        <SimulatorSummaryPanel
            className={classnames(`${baseClassName}__summary`)}
            simulator={simulator}/>
    </SimulatorPanelContainer>
}

export default SimulatorDataSetup;