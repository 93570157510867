import './Simulator.scss';
import React, {ChangeEvent, useEffect, useState} from "react";
import classnames from "classnames";
import {NavLink, Outlet, useLocation, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {SimulatorConfig, SimulatorDataset} from "@/types/types";
import {ByzzerButton, ByzzerSelectOption, ByzzerTipIcon} from "@byzzer/ui-components";
import {SimulatorImportButton} from "@/components/SimulatorImportButton";
import {saveSimulatorAsFile} from "@/services/simulator.service";
import {PanelExpansionProvider} from '@/components/PanelExpansionToggle/PanelExpansionContext';
import {getAllDatasets, getSimulator, saveSimulator} from "@/services/api.service";
import {alert} from '@/components/Modal';

export type SimulatorProps = {} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator';

const steps = ['product', 'data', 'layout', 'preview']

export function Simulator({className, ...props}: SimulatorProps) {

    const navigate = useNavigate();
    const {env = 'qa', id} = useParams<{
        env: string,
        id: string
    }>();
    const [dataset, setDataset] = useState<SimulatorDataset>()
    const [simulator, setSimulator] = useState<SimulatorConfig>({
        active: false,
        allowedAccessLevels: [],
        description: "",
        sku: "",
        thumbnailUrl: "",
        displayName: "",
        calculations: [],
        chartConfig: {
            type: "bar",
            values: [],
            layout: {},
        },
        inputGroups: [],
        leverConfigs: [],
        staticValues: [],
        tableConfigs: []
    });
    const {pathname} = useLocation();
    const stepIndex = steps.indexOf(pathname.split('/').pop()!);
    const [variables, setVariables] = useState<string[]>([]);
    const [variableOptions, setVariableOptions] = useState<ByzzerSelectOption[]>([]);
    const [datasetOptions, setDatasetOptions] = useState<ByzzerSelectOption<SimulatorDataset>[]>([]);
    const [datasetsById, setDatasetsById] = useState<Record<number, SimulatorDataset>>({});
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        ;(async () => {
            const datasets = await getAllDatasets(env);
            setDatasetsById(datasets.reduce((result, dataset) => ({
                ...result,
                [dataset.id]: dataset
            }), {}));
            setDatasetOptions(datasets.map(dataset => ({
                display: dataset.displayName,
                value: dataset.id as any,
                data: dataset
            })))
            setInitialized(true);
        })();
    }, [])

    useEffect(() => {
        ;(async () => {
            if (initialized && id !== undefined) {
                setSimulator(await getSimulator(env, Number(id)));
            }
        })();
    }, [id, initialized])

    useEffect(() => {

        if (!simulator) return;

        const dataset = datasetsById[simulator.datasetId!];
        const allValidCodes = [
            ...simulator.leverConfigs.map(v => v.code),
            ...simulator.calculations.map(v => v.code),
            ...dataset?.valueConfigs.map(v => v.code) ?? [],
        ].filter(Boolean);
        setDataset(dataset);
        setVariables(allValidCodes)
        setVariableOptions(allValidCodes.sort((a, b) => a.localeCompare(b)).map(value => ({
            display: `$${value}`,
            value
        })));
    }, [simulator.leverConfigs, simulator.calculations, simulator.datasetId])

    function handleTitleChange({target}: ChangeEvent<HTMLInputElement>) {
        setSimulator(simulator => ({
            ...simulator,
            displayName: target.value
        }));
    }

    function next() {

        const nextStep = steps[stepIndex + 1];
        if (nextStep) {
            navigate(nextStep);
        }
    }

    function back() {

        const nextStep = steps[stepIndex - 1];
        if (nextStep) {
            navigate(-1)
        }
    }

    function exitEditor() {
        navigate(`/${env}/simulators`, {replace: true})
    }

    async function save() {
        await saveSimulator(env, simulator);
        alert({
            title: 'Saved',
            content: simulator.id ? 'Your simulator was successfully updated.' : 'Your simulator has been created!',
            okLabel: 'Got It'
        })
    }

    function saveToFile() {
        saveSimulatorAsFile(simulator);
    }

    function updateVariableValue(code: string, value: number | string): void {

        // find all affected variables and calculations
        // recalculate all affected values
    }

    function getStepClassName({isActive}) {

        return classnames(`${baseClassName}__step`, {
            [`${baseClassName}__step--active`]: isActive
        });
    }

    return <div className={classnames(baseClassName, className)} {...props}>
        <header className={`${baseClassName}__header`}>
            <h1 className={`${baseClassName}__title`}>
                <div className={`${baseClassName}__env`}>
                    {env}
                </div>
                {Boolean(simulator.id) ? (<>
                    Editing: {simulator.displayName || 'Untitled Simulator'}
                </>) : (<>
                    Creating New Simulator
                </>)}
            </h1>
            <i className={`${baseClassName}__exit-trigger`} onClick={exitEditor}/>
        </header>
        <nav className={`${baseClassName}__steps`}>
            <NavLink className={getStepClassName} to={'product'}>
                <div className={`${baseClassName}__step-text`}>Product Settings</div>
            </NavLink>
            <NavLink className={getStepClassName} to={'data'}>
                <div className={`${baseClassName}__step-text`}>Setup Your Data</div>
            </NavLink>
            <NavLink className={getStepClassName} to={'layout'}>
                <div className={`${baseClassName}__step-text`}>Configure Your Layout</div>
            </NavLink>
            <NavLink className={getStepClassName} to={'preview'}>
                <div className={`${baseClassName}__step-text`}>Preview</div>
            </NavLink>
        </nav>
        <main className={`${baseClassName}__content`}>
            <PanelExpansionProvider>
                <Outlet
                    context={{simulator, setSimulator, dataset, setDataset, variables, variableOptions, datasetOptions, updateVariableValue}}/>
            </PanelExpansionProvider>
        </main>
        <footer className={`${baseClassName}__actions`}>
            <ByzzerButton className={`${baseClassName}__exit-btn`} onClick={exitEditor}>Exit Editor</ByzzerButton>
            <ByzzerButton onClick={save} disabled={!simulator.displayName?.trim()}
                          className={`${baseClassName}__save-btn`}
                          disabledTip={'You have to name your simulator before saving it.'}
                          label={'Save'}/>
            <ByzzerButton onClick={saveToFile} disabled={!simulator.displayName?.trim()}
                          className={`${baseClassName}__export-btn`}
                          disabledTip={'You have to name your simulator before exporting it.'}
                          label={'Export'}/>
            <ByzzerButton onClick={back} disabled={stepIndex === 0}>Back</ByzzerButton>
            <ByzzerButton onClick={next} disabled={stepIndex >= steps.length - 1}>Next</ByzzerButton>
        </footer>
    </div>
}

export type SimulatorContextValue = {
    simulator: SimulatorConfig;
    setSimulator: React.Dispatch<React.SetStateAction<SimulatorConfig>>;
    variables: string[];
    variableOptions: ByzzerSelectOption[];
    updateVariableValue(code: string, value: number | string): void;
    datasetOptions: ByzzerSelectOption<SimulatorDataset>[];
    dataset: SimulatorDataset;
    setDataset: React.Dispatch<React.SetStateAction<SimulatorDataset>>;
    formats: Record<string, Intl.NumberFormatOptions>;
}

export const useSimulator = () => useOutletContext<SimulatorContextValue>();

export default Simulator;