import {SimulatorCalculation, SimulatorConfig, SimulatorDatatype, SimulatorLeverConfig} from "@/types/types";
import convert from "color-convert";
import {Graph} from "graph-data-structure";
import { uniq } from "lodash";

export * from './noop';

export function findAffectedLeverCodes(changedVar: string, formulas: Record<string, string>): string[] {

    const graph = Graph()
    const srcGraph = Graph();

    Object.entries(formulas).forEach(([code, formula]) => {
        extractFormulaVars(formula).forEach(src => {
            graph.addEdge(src, code)
            srcGraph.addEdge(code, src);
        });
    });
    srcGraph.adjacent(changedVar).forEach(src => {
        graph.removeEdge(src, changedVar)
    })
    return graph.depthFirstSearch([changedVar], false);
}

export function extractFormulaVars(formula?: string): string[] {
    return uniq(formula?.match(/\$\$?\w+/g)?.filter(v => !v.startsWith('$$')).map(v => v.replace(/^\$\$?/, '')) ?? []);
}

export function extractOverrideVars(formula?: string): string[] {
    return uniq(formula?.match(/\$\$\w+/g)?.map(v => v.replace(/^\$\$?/, '')) ?? []);
}

export function isNumeric(value: string): boolean {
    return /^-?[0-9]*\.?[0-9]*$/.test(value);
}

const decimalFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
});

const integerFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting'
});

const percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
});

export function rawValueToDisplay(datatype: SimulatorDatatype, value: number | undefined): string {


    if(value === undefined|| isNaN(value as number)) return '';

    switch (datatype) {
        case "integer":
            return integerFormatter.format(value);
        case "currency":
            return currencyFormatter.format(value);
        case "percent":
            // @ts-ignore
            return percentFormatter.format(value);
        case "decimal":
        default:
            return decimalFormatter.format(value);
    }
}

export function rawValueToInput(datatype: SimulatorDatatype, value: number | undefined): string {
    // return input will all display formatting stripped
    return rawValueToDisplay(datatype, value).replace(/[^\d.-]/g, '');
}

export function inputToRawValue(datatype: SimulatorDatatype, input: string): number | undefined {

    input = input.replace(/[^\d.-]/g, '');

    const value = Number(input);

    if(input === '' || isNaN(value)) return undefined;

    switch (datatype) {
        case "integer":
            return Math.round(value);
        case "percent":
            // precision is being used here to account for floating point math issues
            return Number((value / 100).toPrecision(12));
        case "decimal":
        default:
            return value;
    }
}

export function getTypeByCode(code: string, simulator: SimulatorConfig): SimulatorDatatype | undefined {

    const allConfigs: (SimulatorLeverConfig | SimulatorCalculation)[] = [
        ...simulator.calculations,
        ...simulator.leverConfigs
    ];
    const config = allConfigs.find(v => v.code === code);
    return config?.datatype;
}

export function getNextColor(index: number, baseColor: string = '#2D6DF6'): string {
    const hsl = convert.hex.hsl('#2D6DF6');
    hsl[0] = (hsl[0] + index * 20) % 360;
    return `#${convert.hsl.hex(hsl)}`;
}


type WithCode = {
    code: string;
}
export function codeCompare(a: WithCode, b: WithCode): number {
    return a.code.localeCompare(b.code);
}