import './SimulatorPreview.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {SimulatorDatatype, SimulatorValues} from "@/types/types";
import SimulatorChart from './SimulatorChart';
import SimulatorOutputTables from './SimulatorOutputTables';
import SimulatorStaticValues from "@/components/SimulatorPreview/SimulatorStaticValues";
import SimulatorInputLevers from './SimulatorInputLevers';
import {ByzzerChangeEvent} from "@byzzer/ui-components";
import {evaluateFormula} from "@/services/formula.service";
import {useOutletContext} from "react-router-dom";
import {useSimulator} from "@/pages/Simulator";
import SimulatorValueTestPanel from "@/components/SimulatorValueTestPanel/SimulatorValueTestPanel";
import {ValueManager} from "@/utils/ValueManager";

export type SimulatorPreviewProps = {} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator-preview';

export function SimulatorPreview({className, ...props}: SimulatorPreviewProps) {

    const {simulator, dataset} = useSimulator();
    const [values, setValues] = useState<Record<string, number | undefined>>({});
    const [datatypeMap, setDatatypeMap] = useState<Record<string, SimulatorDatatype>>({})

    useEffect(() => {
        if (!simulator || !dataset) return;

        const {leverConfigs, calculations} = simulator;
        const {valueConfigs} = dataset;

        setDatatypeMap([...leverConfigs, ...calculations, ...valueConfigs].reduce((result, {code, datatype}) => ({
            ...result,
            [code]: datatype ?? 'decimal'
        }), {}));
    }, [simulator.leverConfigs, simulator.calculations, dataset]);

    function handleLeverChange(e: ByzzerChangeEvent<Record<string, number | undefined>>): void {
        setValues(e.value);
    }


    return <div className={classnames(baseClassName, className)} {...props}>

        <div className={`${baseClassName}__inputs`}>
            <SimulatorInputLevers simulator={simulator}
                                  values={values}
                                  onChange={handleLeverChange}/>
            <SimulatorStaticValues simulator={simulator}
                                   datatypeMap={datatypeMap}
                                   values={values}/>
        </div>
        <div className={`${baseClassName}__outputs`}>
            <header>
                <h1 className={`${baseClassName}__title`}>Outcomes</h1>
            </header>
            <SimulatorChart className={`${baseClassName}__chart`}
                            datatypeMap={datatypeMap}
                            values={values}
                            config={simulator?.chartConfig}/>
            <SimulatorOutputTables tables={simulator.tableConfigs}
                                   values={values}
                                   datatypeMap={datatypeMap}/>
        </div>
        {/*<SimulatorValueTestPanel className={`${baseClassName}__values`}*/}
        {/*                         values={values}*/}
        {/*                         excludeLevers={true}*/}
        {/*                         simulator={simulator}*/}
        {/*                         onChange={handleLeverChange}/>*/}
    </div>
}

export default SimulatorPreview;