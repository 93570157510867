export const VALID_FUNCTIONS: string[] = [
    'ABS',
    'ACCRINT',
    'ACOS',
    'ACOSH',
    'ACOT',
    'ACOTH',
    'AGGREGATE',
    'AND',
    'ARABIC',
    'ASIN',
    'ASINH',
    'ATAN',
    'ATAN2',
    'ATANH',
    'AVEDEV',
    'AVERAGE',
    'AVERAGEA',
    'AVERAGEIF',
    'AVERAGEIFS',
    'BASE',
    'BETADIST',
    'BETAINV',
    'BIN2DEC',
    'BIN2HEX',
    'BIN2OCT',
    'BINOMDIST',
    'BITAND',
    'BITLSHIFT',
    'BITOR',
    'BITRSHIFT',
    'BITXOR',
    'CEILING',
    'CEILINGMATH',
    'CEILINGPRECISE',
    'CHAR',
    'CLEAN',
    'CODE',
    'COMBIN',
    'COMBINA',
    'COMPLEX',
    'CONCATENATE',
    'CONVERT',
    'CORREL',
    'COS',
    'COSH',
    'COT',
    'COTH',
    'COUNT',
    'COUNTA',
    'COUNTBLANK',
    'COUNTIF',
    'COUNTIFS',
    'COUNTUNIQUE',
    'COVARIANCEP',
    'COVARIANCES',
    'CSC',
    'CSCH',
    'CUMIPMT',
    'CUMPRINC',
    'DATE',
    'DATEVALUE',
    'DAY',
    'DAYS',
    'DAYS360',
    'DB',
    'DDB',
    'DEC2BIN',
    'DEC2HEX',
    'DEC2OCT',
    'DECIMAL',
    'DELTA',
    'DEVSQ',
    'DOLLARDE',
    'DOLLARFR',
    'EDATE',
    'EFFECT',
    'EOMONTH',
    'ERF',
    'ERF',
    'ERFC',
    'ERFC',
    'EVEN',
    'EXACT',
    'EXP',
    'EXPONDIST',
    'FACT',
    'FACTDOUBLE',
    'FALSE',
    'FDIST',
    'FIND',
    'FINV',
    'FISHER',
    'FISHERINV',
    'FLOOR',
    'FLOORMATH',
    'FLOORPRECISE',
    'FORECAST',
    'FREQUENCY',
    'FV',
    'FVSCHEDULE',
    'GAMMA',
    'GAMMALN',
    'GAUSS',
    'GCD',
    'GEOMEAN',
    'GESTEP',
    'GROWTH',
    'HARMEAN',
    'HEX2BIN',
    'HEX2DEC',
    'HEX2OCT',
    'HOUR',
    'HYPGEOMDIST',
    'IF',
    'IFERROR',
    'IFNA',
    'IFS',
    'IMABS',
    'IMAGINARY',
    'IMARGUMENT',
    'IMCONJUGATE',
    'IMCOS',
    'IMCOSH',
    'IMCOT',
    'IMCSC',
    'IMCSCH',
    'IMDIV',
    'IMEXP',
    'IMLN',
    'IMLOG10',
    'IMLOG2',
    'IMPOWER',
    'IMPRODUCT',
    'IMREAL',
    'IMSEC',
    'IMSECH',
    'IMSIN',
    'IMSINH',
    'IMSQRT',
    'IMSUB',
    'IMSUM',
    'IMTAN',
    'INT',
    'INTERCEPT',
    'IPMT',
    'IRR',
    'ISEVEN',
    'ISNULL',
    'ISBLANK',
    'ISOCEILING',
    'ISODD',
    'ISOWEEKNUM',
    'ISPMT',
    'KURT',
    'LARGE',
    'LCM',
    'LEFT',
    'LEN',
    'LINEST',
    'LN',
    'LOG',
    'LOG10',
    'LOGNORMDIST',
    'LOGNORMINV',
    'LOWER',
    'MAX',
    'MAXA',
    'MEDIAN',
    'MID',
    'MIN',
    'MINA',
    'MINUTE',
    'MIRR',
    'MOD',
    'MODEMULT',
    'MODESNGL',
    'MONTH',
    'MROUND',
    'MULTINOMIAL',
    'NETWORKDAYS',
    'NETWORKDAYSINTL',
    'NOMINAL',
    'NORMDIST',
    'NORMINV',
    'NORMSDIST',
    'NORMSINV',
    'NOT',
    'NOW',
    'NPER',
    'NPV',
    'NUMBERVALUE',
    'OCT2BIN',
    'OCT2DEC',
    'OCT2HEX',
    'ODD',
    'OR',
    'PDURATION',
    'PEARSON',
    'PERCENTILEEXC',
    'PERCENTILEINC',
    'PERCENTRANKEXC',
    'PERCENTRANKINC',
    'PERMUT',
    'PERMUTATIONA',
    'PHI',
    'PMT',
    'POISSONDIST',
    'POWER',
    'PPMT',
    'PROB',
    'PRODUCT',
    'PROPER',
    'PV',
    'QUARTILEEXC',
    'QUARTILEINC',
    'QUOTIENT',
    'RADIANS',
    'RAND',
    'RANDBETWEEN',
    'RANKAVG',
    'RANKEQ',
    'RATE',
    'REGEXEXTRACT',
    'REGEXMATCH',
    'REGEXREPLACE',
    'REPLACE',
    'REPT',
    'RIGHT',
    'ROMAN',
    'ROUND',
    'ROUNDDOWN',
    'ROUNDUP',
    'RSQ',
    'SEARCH',
    'SEC',
    'SECH',
    'SECOND',
    'SIGN',
    'SIN',
    'SINH',
    'SKEW',
    'SKEWP',
    'SLOPE',
    'SMALL',
    'SPLIT',
    'SQRT',
    'SQRTPI',
    'STANDARDIZE',
    'STDEVA',
    'STDEVP',
    'STDEVPA',
    'STDEVS',
    'STEYX',
    'SUBSTITUTE',
    'SUBTOTAL',
    'SUM',
    'SUMIF',
    'SUMIFS',
    'SUMPRODUCT',
    'SUMSQ',
    'SUMX2MY2',
    'SUMX2PY2',
    'SUMXMY2',
    'SWITCH',
    'T',
    'TAN',
    'TANH',
    'TDIST',
    'TIME',
    'TIMEVALUE',
    'TINV',
    'TODAY',
    'TRIM',
    'TRIMMEAN',
    'TRUE',
    'TRUNC',
    'UNICHAR',
    'UNICODE',
    'UPPER',
    'VARA',
    'VARP',
    'VARPA',
    'VARS',
    'WEEKDAY',
    'WEEKNUM',
    'WEIBULLDIST',
    'WORKDAY',
    'WORKDAYINTL',
    'XOR',
    'YEAR',
    'YEARFRAC',
    'ZTEST',
]