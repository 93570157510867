import './Modal.scss';
import React, {useEffect, useRef} from 'react';
import {create, InstanceProps} from 'react-modal-promise';
import classNames from 'classnames';
import {noop} from "@/utils";

import {AnimatePresence, motion, Transition, Variants} from "framer-motion";
import {ByzzerButton} from "@byzzer/ui-components";
import ByzzerModal from "@/components/Modal/ByzzerModal/ByzzerModal";
import {nanoid} from 'nanoid';
import {isFunction} from "lodash";
import {JSX} from "react/jsx-runtime";
import IntrinsicAttributes = JSX.IntrinsicAttributes;

export type ModalType = 'default' | 'error' | 'info' | 'warning'

export type ModalProps = {
    id?: string,
    onClick?: Function;
    heading?: string;
    type?: ModalType;
    onClose?: Function;
    closeOnBlur?: boolean;
    closeButtonText?: string;
    show?: boolean;
    [prop: string]: any;
}
const baseClassName = 'modal';
const Modal = ({
                   onClick = noop,
                   onClose = onClick,
                   heading,
                   type = 'default',
                   closeOnBlur,
                   closeButtonText,
                   show,
                   className,
                   children,
                   ...props
               }: ModalProps) => {

    const ref = useRef<HTMLDivElement>(null);

    const wrapperClassNames = classNames('modal-wrapper', className, {
        [`modal-wrapper--${type}`]: true,
    });

    const headerClassNames = classNames(`modal-header`, {
        [`modal-header--with-title`]: heading
    });

    const modalClassNames = classNames(baseClassName, {
        [`${baseClassName}--${type}`]: true,
    });

    const onWrapperClick = (event) => {
        if (closeOnBlur && !ref.current?.contains(event.target)) {
            return onClose?.();
        }
    }

    return (
        <div className={wrapperClassNames} {...props} onClick={onWrapperClick}>
            <div className={modalClassNames} ref={ref}>
                <header className={`modal-header`}>
                    <h1 className={`modal-header__title`}>{heading}</h1>
                    <i className={`modal-header__close`} onClick={() => onClose?.()}/>
                </header>
                <main className={'modal-content'}>
                    {children}
                </main>
            </div>
        </div>
    );
};

export function ModalMask({className, showIf, children, ...props}) {
    if (showIf) {
        return (
            <div className={classNames('modal__loading-mask', className)} {...props}>
                <svg
                    className={'modal__loading-spinner'}
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle pathLength={1} cx="50" cy="50" r="45"/>
                </svg>
                {children && <div className={'modal__loading-text'}>{children}</div>}
            </div>
        );
    }

    return <></>;
}

export type ModalContextValue = {
    isOpen: boolean;
    resolve: (value?: any) => void;
    reject: (value?: any) => void;
}
export const ModalContext = React.createContext<ModalContextValue>({} as any);
export const useModalContext = () => React.useContext(ModalContext);


export type ConfirmProps<T> = {
    type?: ModalType;
    title?: string;
    content?: string | JSX.Element;
    yesLabel?: string;
    noLabel?: string;
    closeButton: boolean;
    [prop: string]: any;
} & InstanceProps<T>

export const confirm = create(
    ({
         onResolve,
         title,
         content,
         yesLabel = 'Yes',
         noLabel = 'No',
         closeButton = false,
         className,
         ...props
     }: ConfirmProps<any>) => {
        return (
            <div className={classNames('modal-wrapper', 'modal-wrapper--visible')}>
                <div className={`confirm ${className}`}>
                    <header className={`confirm__header`}>
                        <h1>{title}</h1>
                    </header>
                    <main className={'confirm__content'}>{content}</main>
                    <footer className={'confirm__footer'}>
                        <ByzzerButton onClick={() => onResolve(true)} label={yesLabel}/>
                        <ByzzerButton onClick={() => onResolve(false)} label={noLabel}/>
                    </footer>
                </div>
            </div>
        );
    }
);

export type AlertOptions = {
    type?: ModalType;
    title?: string | JSX.Element;
    content: string | JSX.Element;
    okLabel?: string;
    showCloseButton?: boolean;
    [prop: string]: any;
}

interface AlertFunction {
    (options: AlertOptions): Promise<any>
}

export const alert: AlertFunction = create(({
                                                onResolve,
                                                type,
                                                title,
                                                content,
                                                okLabel,
                                                className,
                                                ...props
                                            }: AlertOptions & InstanceProps<any>) => {
    const modalClasses = classNames(className, `alert-modal`, {
        [`alert-modal--${type}`]: Boolean(type),
    });

    const variants: Variants = {
        in: {
            opacity: 1,
            y: 0
        },
        out: {
            opacity: 0,
            y: '100px'
        }
    }

    const timing: Transition = {
        duration: .1
    }
    return (
        <AnimatePresence>
            <div className={classNames('modal-wrapper', 'modal-wrapper--visible', 'modal-wrapper--alert')}>
                <motion.div className={modalClasses} variants={variants} initial="out" animate="in" exit="out"
                            transition={timing}>
                    <div className={'alert-modal__header-container'}>
                        {Boolean(title) && (
                            <header className={`alert-modal__header`}>
                                {title}
                            </header>
                        )}
                        <span className={'alert-modal__close-icon'} onClick={() => onResolve(true)}></span>
                    </div>
                    <main className={'alert-modal__content'}>{content}</main>
                    {okLabel &&
                        <footer className={'alert-modal__footer'}>
                            <ByzzerButton onClick={() => onResolve(true)} label={okLabel}/>
                        </footer>}
                </motion.div>
            </div>
        </AnimatePresence>
    );
});

export type ErrorModalProps = {
    title: string;
    content: string | JSX.Element;
    displayErrorId?: boolean;
    errorId?: string;
}

export async function openErrorModal({title, content, displayErrorId = false, errorId}: ErrorModalProps) {
    let errorInfo;

    if (displayErrorId && errorId) {
        // add a dash and convert to uppercase
        const formattedErrorId = errorId.replace(/^(....)/, '$1-').toUpperCase();

        errorInfo = (
            <div className={'modal-content__reference-code'}>
                Your reference code is <em className={'error-id'}>{formattedErrorId}</em>.
            </div>
        );
    }

    return openModal({
        title,
        content: (
            <>
                {content}
                {errorInfo}
            </>
        ),
    });
}

export interface ModalPromise<ResolveType = any> extends Promise<ResolveType> {
    id: string;

    close(value?: ResolveType): void;

    cancel(reason?: any): void;
}

export function modalify<ValueType, PropsType = never>(Component: React.ComponentType<PropsType>, defaultTitle: string = ''):
    (props?: PropsType & {title?: string}) => ModalPromise<ValueType> {

    return (props?: PropsType & {title?: string}) => {
        const {title = defaultTitle, ...componentProps} = props ?? {};
        return openModal({
            title: title,
            content: <Component {...componentProps as any}/>
        });
    }
}

export function openModal<ValueType = any>({id = nanoid(), ...props}: ModalProps): ModalPromise<ValueType> {

    const launch = create(({content, ...props}: ModalProps & InstanceProps<any>) => {
        return (
            <Modal className={props.className}
                   heading={props.title}
                   show={props.isOpen}
                   onClose={props.onResolve}>
                <ModalContext.Provider value={{
                    resolve: props.onResolve,
                    reject: props.onReject,
                    isOpen: props.isOpen
                }}>
                    {isFunction(content) ? content({close: props.onResolve, cancel: props.onReject, id}) : content}
                </ModalContext.Provider>
            </Modal>
        );
    });

    const mp: ModalPromise = launch({
        instanceId: id,
        ...props,
    }) as ModalPromise;

    mp.id = id;
    mp.close = (value?: ValueType) => {
        window.byzzerModals.resolve(id);
    };
    mp.cancel = (reason?: any) => {
        window.byzzerModals.reject(id);
    };

    return mp;
}

export const useModals = () => ({
    openModal,
    openErrorModal,
    confirm,
    alert,
});

export default Modal;
