import {RouteMetadata} from "../utils/RouteBuilder";
import {User} from 'firebase/auth';

export function unauthenticated(meta: RouteMetadata, user?: User | null): string | undefined {

    if(user) {
        return '/qa/simulators';
    }
}

export function authenticated(meta: RouteMetadata, user?: User | null): string | undefined {

    if(!user) {
        return '/login';
    }
}