import './WhatsNew.scss';
import React, {ReactNode, useState} from "react";
import compare from 'semver-compare';
import {buildInfo} from "@/config";
import {create, InstanceProps} from "react-modal-promise";
import classNames from "classnames";
import {ByzzerButton} from "@byzzer/ui-components";
import {AnimatePresence, motion, Transition, Variants} from "framer-motion";
import {releaseNotes} from "@/config/releaseNote";

const LAST_VERSION_USED_KEY = 'lastVersionUsed';
const lastVersionUsed = localStorage[LAST_VERSION_USED_KEY];

const baseClasName = 'whats-new';
const variants: Variants = {
    in: {
        opacity: 1,
        y: 0
    },
    out: {
        opacity: 0,
        y: '100px'
    }
}

type WhatsNewProps = {
    className?: string;
};

type WhatsNewFunction = {
    (options?: WhatsNewProps): Promise<any>
}

export function hasNewReleaseNotes(): boolean {

    if(!lastVersionUsed) {
        localStorage[LAST_VERSION_USED_KEY] = buildInfo.version;
        return false;
    }

    return Object.keys(releaseNotes).some(version => compare(version, lastVersionUsed) > 0);
}

export const showWhatsNewModal: WhatsNewFunction = create(({
                                                               onResolve,
                                                               className,
                                                               ...props
                                                           }: WhatsNewProps & InstanceProps<any>) => {

    const [messages] = useState<ReactNode[]>(Object.entries(releaseNotes)
        .filter(([version]) => lastVersionUsed && compare(version, lastVersionUsed) > 0)
        .map(([, {messages}]) => messages)
        .flat());

    function handleOk() {
        localStorage[LAST_VERSION_USED_KEY] = buildInfo.version;
        onResolve(true);
    }

    const timing: Transition = {
        duration: .1
    }
    return (
        <AnimatePresence>
            <div className={classNames('modal-wrapper', 'modal-wrapper--visible')}>
                <motion.div className={baseClasName} variants={variants} initial="out" animate="in" exit="out"
                            transition={timing}>
                    <header className={`${baseClasName}__header`}>
                        What's new in Sim Studio v{buildInfo.version}?
                    </header>
                    <main className={`${baseClasName}__content`}>
                        <ol className={`${baseClasName}__messages`}>
                        {messages.map(message => (
                            <li className={`${baseClasName}__message`}>{message}</li>
                        ))}
                        </ol>
                    </main>
                    <footer className={`${baseClasName}__footer`}>
                        <ByzzerButton onClick={handleOk} label="Got It"/>
                    </footer>
                </motion.div>
            </div>
        </AnimatePresence>
    );
});



