import {evaluateFormula} from "@/services/formula.service";
import {extractFormulaVars, extractOverrideVars, inputToRawValue, rawValueToInput} from "@/utils/index";
import {Graph} from "graph-data-structure";
import {SimulatorCalculation, SimulatorDatasetValue, SimulatorDatatype, SimulatorLeverConfig} from "@/types/types";

export type UpdateLeverOptions = {
    clear?: string[];
    raw?: boolean;
}

export type CodeDatatypePair = {
    code: string;
    datatype?: SimulatorDatatype;
}

export type CodeFormulaPair = {
    code: string;
    formula?: string;
}

export type CodeSampleValuePair = {
    code: string;
    sampleValue?: number;
}

type ConstructorParam = {
    staticValueConfigs?: SimulatorDatasetValue[],
    calculations?: SimulatorCalculation[],
    leverConfigs?: SimulatorLeverConfig[]
}

export class ValueManager {

    private calculations: Record<string, string | undefined> = {};
    private staticValues: Record<string, number> = {};
    private levers: Record<string, number | undefined> = {};
    private rawValues: Record<string, number | undefined> = {};
    private formattedValues: Record<string, string> = {};
    private variableResetMap: Record<string, string[]> = {};
    private datatypes: Record<string, SimulatorDatatype> = {};
    private persistedCodes: string[] = [];

    constructor();
    constructor(params: ConstructorParam);
    constructor(params?: ConstructorParam) {
        const {leverConfigs = [], staticValueConfigs = [], calculations = []} = params ?? {}
        this.setDatatypes([...leverConfigs, ...calculations, ...staticValueConfigs], true);
        this.setStaticValues(staticValueConfigs, true);
        this.setCalculations([...leverConfigs, ...calculations]);
        // we are not skipping the recalc in this call so that the raw values are populated for the next step
        this.setVariableResetMap(leverConfigs);
        // it's important that this is last
        this.persistedCodes = leverConfigs.filter(l => l.persist).map(l => l.code);
        this.persistedCodes.forEach(code => {
            if(this.rawValues[code] !== undefined) {
                this.levers[code] = this.rawValues[code];
            } else {
                delete this.levers[code];
            }
        })
        this.recalculate();
    }

    public reset() {
        this.calculations = {};
        this.staticValues = {};
        this.levers = {};
        this.rawValues = {};
        this.formattedValues = {};
        this.variableResetMap = {};
        this.datatypes = {};
    }

    public getRawValues(): Record<string, number | undefined> {
        return this.rawValues;
    }

    public getFormattedValues(): Record<string, string> {
        return this.formattedValues;
    }

    public clearLevers(skipRecalc: boolean = false): Record<string, number | undefined> {

        this.levers = {};
        return this.recalculate();
    }

    public removeLevers(variables: string[]): Record<string, number | undefined> {

        variables.forEach(variable => {
            delete this.levers[variable];
        });

        return this.recalculate();
    }

    public setVariableResetMap(levers: SimulatorLeverConfig[]): void;
    public setVariableResetMap(resetMap: Record<string, string[]>): void;
    public setVariableResetMap(leversOrMap: Record<string, string[]> | SimulatorLeverConfig[]): void {

        if(Array.isArray(leversOrMap)) {
            this.variableResetMap = leversOrMap.reduce((result, {code, resetCodes}) => ({
                ...result,
                [code]: resetCodes
            }), {})
        } else {
            this.variableResetMap = leversOrMap
        }
    }

    public setDatatypes(datatypes: CodeDatatypePair[], skipRecalc?: boolean): Record<string, string>;
    public setDatatypes(datatypes: Record<string, SimulatorDatatype>, skipRecalc?: boolean): Record<string, string>;
    public setDatatypes(datatypes: CodeDatatypePair[] | Record<string, SimulatorDatatype>, skipRecalc: boolean = false): Record<string, string> {

        if(Array.isArray(datatypes)) {
            this.datatypes = datatypes.reduce((result, {code, datatype}) => ({
                ...result,
                [code]: datatype ?? 'decimal'
            }), {});
        } else {
            this.datatypes = datatypes;
        }

        if(!skipRecalc) {
            this.recalculate();
        }
        return this.formattedValues;
    }

    public updateLever(variable: string, rawValue: number | string | undefined, {
        clear = this.variableResetMap[variable] ?? [],
        raw = false
    }: UpdateLeverOptions = {}): Record<string, number | undefined> {

        let value: number | undefined;
        if(raw) {
            value = inputToRawValue(this.datatypes[variable] ?? 'decimal', rawValue as string)
        } else {
            value = rawValue as number;
        }

        const graph = Graph()
        const srcGraph = Graph();
        Object.entries(this.calculations).forEach(([code, formula]) => {
            // always clear lever values with an explicit reference the one being updated
            if(extractOverrideVars(formula).includes(variable)) {
                delete this.levers[code];
            }
            extractFormulaVars(formula).forEach(src => {
                graph.addEdge(src, code)
                srcGraph.addEdge(code, src);
            })
        });

        srcGraph.adjacent(variable).forEach(src => {
            graph.removeEdge(src, variable)
        });
        // clear all effected lever variables and any explicitly included in the params
        [...clear, ...graph.depthFirstSearch([variable], false)].forEach(affectedVariable => {
            if(!this.persistedCodes.includes(affectedVariable)) {
                delete this.levers[affectedVariable];
            }
        });

        this.levers[variable] = value;
        const values = this.recalculate();
        return values;
    }

    public setCalculations(calculations: CodeFormulaPair[], skipRecalc?: boolean): Record<string, number | undefined>;
    public setCalculations(calculations: Record<string, string>, skipRecalc?: boolean): Record<string, number | undefined>;
    public setCalculations(calculations: CodeFormulaPair[] | Record<string, string>, skipRecalc: boolean = false): Record<string, number | undefined> {

        if(Array.isArray(calculations)) {
            this.calculations = calculations.filter(v => Boolean(v.formula)).reduce((result, {code, formula}) => ({
                ...result,
                [code]: formula
            }), {})
        } else {
            this.calculations = calculations;
        }

        for (let variable in this.calculations) {
            this.calculations[variable] = `(${this.calculations[variable]})`
        }

        if(!skipRecalc) {
            this.recalculate();
        }
        return this.rawValues;
    }

    public setStaticValues(sampleValues: CodeSampleValuePair[], skipRecalc?: boolean): Record<string, number | undefined>;
    public setStaticValues(values: Record<string, number>, skipRecalc?: boolean): Record<string, number | undefined>;
    public setStaticValues(values:  CodeSampleValuePair[] | Record<string, number>, skipRecalc: boolean = false): Record<string, number | undefined> {

        if(Array.isArray(values)) {
            this.staticValues = values.reduce((result, {code, sampleValue}) => ({
                ...result,
                [code]: sampleValue
            }), {});
        } else {
            this.staticValues = values;
        }
        if(!skipRecalc) {
            this.recalculate();
        }
        return this.rawValues;
    }

    private recalculate(): Record<string, number | undefined> {
        const calculatedValues = Object.entries(this.calculations)
            .reduce((result, [variable, formula]) => ({
                ...result,
                [variable]: evaluateFormula(formula, {
                    ...this.calculations,
                    ...this.staticValues,
                    ...this.levers
                }, {
                    ...this.staticValues,
                    ...this.levers
                }, variable)
            }), {});

        // override the calculated values with static and defined levers
        this.rawValues = {
            ...calculatedValues,
            ...this.staticValues,
            ...this.levers
        }

        // update persisted values
        this.persistedCodes.forEach(code => {
            if(this.rawValues[code] !== undefined) {
                this.levers[code] = this.rawValues[code];
            } else {
                delete this.levers[code];
            }
        })

        this.formattedValues = Object.entries(this.rawValues).reduce((result, [variable, value]) => ({
            ...result,
            [variable]: rawValueToInput(this.datatypes[variable] ?? 'decimal', value)
        }), {});

        return this.rawValues;
    }
}