import React, {createContext, RefObject, useContext, useEffect, useMemo, useRef} from "react";
import ModalContainer, {ContainerRef} from "react-modal-promise";
import {useLocation} from "react-router-dom";

const ModalContext = createContext<RefObject<ContainerRef> | null>(null);
ModalContext.displayName = 'ModalContext';

export type ByzzerModalContainerRef = {
    reject(id: string, reason?: any): void;
    resolve(id: string, value?: any): void;
    rejectAll(reason?: any): void;
    resolveAll(): void;
    getContainer(): ContainerRef | null | undefined;
}

declare global {
    interface Window {
        byzzerModals: ByzzerModalContainerRef
    }
}

export const useModalContainer = (): ContainerRef => useContext(ModalContext)!.current!;

export function ModalProvider({children}) {

    const containerRef = useRef<ContainerRef>(null);
    const location = useLocation();

    useEffect(() => {
        containerRef.current?.rejectAll();
    }, [location]);

    useEffect(() => {
        window.byzzerModals = {
            reject(id: string, reason?: any) {
                containerRef.current?.reject(id, reason);
            },
            resolve(id: string, value?: any) {
                containerRef.current?.resolve(id, value);
            },
            rejectAll(reason?: any) {
                containerRef.current?.rejectAll(reason)
            },
            resolveAll() {
                containerRef.current?.resolveAll();
            },
            getContainer() {
                return containerRef.current;
            }
        }
    }, [containerRef]);

    return (
        <ModalContext.Provider value={containerRef}>
            {children}
            <ModalContainer ref={containerRef} exitTimeout={0} enterTimeout={0}/>
        </ModalContext.Provider>
    );
}

ModalProvider.displayName = 'ModalProvider';
export default ModalProvider;


