import './SimulatorDatasetPicker.scss';
import React, {Fragment, useRef} from "react";
import classnames from "classnames";
import {SimulatorDataset} from "@/types/types";
import {
    ByzzerChangeEvent,
    ByzzerChangeEventHandler,
    ByzzerSelect,
    ByzzerSelectOption,
    WithValue
} from "@byzzer/ui-components";
import {SimulatorPanel} from "@/components/SimulatorPanel";
import {useSimulator} from "@/pages/Simulator";
import {codeCompare} from "@/utils";

export type SimulatorDatasetPickerProps = {
    name?: string;
    value: number | undefined;
    onChange: ByzzerChangeEventHandler<SimulatorDataset>;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'simulator-dataset-picker';

export function SimulatorDatasetPicker({className, name, value, onChange, ...props}: SimulatorDatasetPickerProps) {

    const {datasetOptions, dataset} = useSimulator();

    function handleDatasetChange(e: ByzzerChangeEvent<string>): void {

        onChange?.({
            name,
            value: e.data.data
        })
    }

    return <SimulatorPanel
        className={classnames(baseClassName, className)}
        title={'Choose Your Dataset'}
        name={'datasetEditor'}
        tip={<>
            <p>Datasets are simulator inputs returned from the server.</p>
            <p>You can configure the dataset selectors in Alby just like reports.</p>
            <p>In the future you will be able to define your own queries to create custom datasets.</p>
        </>}
        primaryControl={
            <ByzzerSelect className={`${baseClassName}__selector`}
                          allowClear={false}
                          options={datasetOptions}
                          onChange={handleDatasetChange as any}
                          value={value as any}/>
        }
        empty={!dataset}
        emptyContent={'You haven\'t selected a Dataset.'}
        {...props}>

        {dataset?.valueConfigs.slice().sort(codeCompare).map(v => (
            <Fragment key={v.code}>
                <div className={`${baseClassName}__label`}>{v.label}</div>
                <div className={`${baseClassName}__code`}>${v.code}</div>
            </Fragment>
        ))}
    </SimulatorPanel>
}

export default SimulatorDatasetPicker;