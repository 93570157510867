import './SimulatorImportButton.scss';
import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import classnames from "classnames";
import {SimulatorConfig} from "@/types/types";
import {importSimulator} from "@/services/simulator.service";

export type SimulatorImportButtonProps = {
    className?: string;
    onImport?(simulator: SimulatorConfig): void;
    children: ReactNode;
};

const baseClassName = 'simulator-import-button';

export function SimulatorImportButton({className, onImport, children}: SimulatorImportButtonProps) {

    async function handleImport({target}: ChangeEvent<HTMLInputElement>) {
        onImport?.(await importSimulator(target.files!));
    }

    return <label className={classnames(baseClassName, 'byz-button', className)}>
        <input type={'file'} onChange={handleImport}/>
        {children}
    </label>
}

export default SimulatorImportButton;