import './SimulatorSummaryPanel.scss';
import React, {ChangeEvent, useEffect, useState} from "react";
import classnames from "classnames";
import {ByzzerButton, ByzzerChangeEventHandler, ByzzerTipIcon} from '@byzzer/ui-components';
import {SimulatorCalculation, SimulatorConfig, SimulatorDataset, SimulatorLeverConfig} from "@/types/types";
import PanelExpansionToggle from "@/components/PanelExpansionToggle/PanelExpansionToggle";
import {SimulatorPanel} from "@/components/SimulatorPanel";
import {useSimulator} from "@/pages/Simulator";
import {codeCompare} from "@/utils";

export type SimulatorSummaryPanelProps = {
    name?: string;
    simulator: SimulatorConfig;
} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator-summary-panel';

export function SimulatorSummaryPanel({
                                          className,
                                          name,
                                          simulator,
                                          ...props
                                      }: SimulatorSummaryPanelProps) {

    const [validLeverConfigs, setValidLeverConfigs] = useState<SimulatorLeverConfig[]>([]);
    const {dataset, formats} = useSimulator();
    const {
        calculations
    } = simulator;

    useEffect(() => {
        setValidLeverConfigs(simulator.leverConfigs?.filter(l => l.code) ?? []);
    }, [simulator.leverConfigs])

    return <SimulatorPanel className={classnames(baseClassName, className)}
                           title={'Summary'}
                           name={'summary'}
                           {...props}>
        <div className={`${baseClassName}__levers`}>
            <h2>Levers</h2>
            <div className={`${baseClassName}__vars`}>
                {validLeverConfigs.sort(codeCompare).map(({code, datatype}) => (
                    <div key={code}
                         className={classnames(`${baseClassName}__var`, `${baseClassName}__var--${datatype}`)}>
                        ${code}
                    </div>
                ))}
                {Boolean(!validLeverConfigs.length) && (
                    <div className={`${baseClassName}__no-vars`}>
                        No Available Levers
                    </div>
                )}
            </div>
        </div>
        <div className={`${baseClassName}__dataset`}>
            <h2>Dataset: {dataset?.displayName ?? 'None Selected'}</h2>
            <div className={`${baseClassName}__vars`}>
                {dataset?.valueConfigs.slice().sort(codeCompare).map(({code}) => (
                    <div className={`${baseClassName}__var`} key={code}>
                        ${code}
                    </div>
                ))}
                {Boolean(!dataset?.valueConfigs.length) && (
                    <div className={`${baseClassName}__no-vars`}>
                        No Available Dataset Variables
                    </div>
                )}
            </div>
        </div>
        <div className={`${baseClassName}__calculations`}>
            <h2>Calculations</h2>
            <div className={`${baseClassName}__vars`}>
                {calculations?.filter(c => Boolean(c.code)).sort(codeCompare).map(({code}) => (
                    <div className={`${baseClassName}__var`} key={code}>
                        ${code}
                    </div>
                ))}
                {Boolean(!calculations?.length) && (
                    <div className={`${baseClassName}__no-vars`}>
                        No Available Formulas
                    </div>
                )}
            </div>
        </div>
    </SimulatorPanel>
}


export default SimulatorSummaryPanel;