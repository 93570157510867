import './SimulatorChartValueEditor.scss';
import React, {ChangeEvent, useEffect, useState} from "react";
import classnames from "classnames";
import {SimulatorChartValueConfig, SimulatorConfig} from "@/types/types";
import {
    ByzzerButton,
    ByzzerChangeEvent,
    ByzzerChangeEventHandler,
    ByzzerSelect,
    ByzzerSelectOption
} from "@byzzer/ui-components";
import {handleInputChange} from "react-select/dist/declarations/src/utils";
import {useOutletContext} from "react-router-dom";
import {useSimulator} from "@/pages/Simulator";

export type SimulatorChartValueEditorProps = {
    name?: string;
    simulator: SimulatorConfig;
    value: SimulatorChartValueConfig;
    onChange: ByzzerChangeEventHandler<SimulatorChartValueConfig>
    onRemove?(name: string): void;
} & Partial<Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'>>;

const baseClassName = 'simulator-chart-value-editor';

export function SimulatorChartValueEditor({className, name, simulator, value, onChange, onRemove, ...props}: SimulatorChartValueEditorProps) {

    const {variableOptions} = useSimulator()

    function handleChange({target}: ChangeEvent<HTMLInputElement>): void {
        onChange({
            name,
            value: {
                ...value,
                [target.name]: target.value
            }
        })
    }

    function handleSourceChange(e: ByzzerChangeEvent<string>): void {
        onChange({
            name,
            value: {
                ...value,
                [e.name!]: e.value
            }
        })
    }

    return <div className={classnames(baseClassName, className)} {...props}>

        <input type={'color'} name={'color'} value={value.color} onChange={handleChange}/>
        <input className={`${baseClassName}__label`}
               type={'text'}
               value={value.label}
               name={'label'}
               onChange={handleChange}/>
        <ByzzerSelect name={'code'}
                      className={`${baseClassName}__lever-selector`}
                      value={value.code}
                      onChange={handleSourceChange as any}
                      allowClear={false}
                      options={variableOptions}/>
        <ByzzerButton onClick={() => onRemove?.(name!)}
                      type={'remove'}
                      iconType={'delete'}
                      tipDelay={[250,0]}
                      tipLocation={'bottom'}
                      tip={'Remove'}/>
    </div>
}

export default SimulatorChartValueEditor;