import './SimulatorPanelContainer.scss';
import React, {useEffect, useState} from "react";
import classnames from "classnames";
import {usePanelExpansion} from "@/components/PanelExpansionToggle/PanelExpansionContext";

export type SimulatorPanelContainerProps = {} & React.HTMLAttributes<HTMLDivElement>;

const baseClassName = 'simulator-panel-container';

export function SimulatorPanelContainer({className, children, ...props}: SimulatorPanelContainerProps) {
    const {activePanel} = usePanelExpansion();
    return <div className={classnames(baseClassName, className, {
        [`${baseClassName}--expanded`]: activePanel
    } )} {...props}>
        {children}
    </div>
}

export default SimulatorPanelContainer;